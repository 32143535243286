import { Button } from "antd";
import React, { Component } from "react";
import "./ProductPresent.css";

export default class ProductPresent extends Component {
  render() {
    if (this.props.reverse) {
      return (
        <div className="pp-container">
          <div className="pp-img-container-r">
            <img
              className="pp-img"
              src={this.props.img}
              alt={this.props.alt}
            />
          </div>
          <div className="pp-content">
            <h2 className="pp-h">{this.props.title}</h2>
            <p className="pp-p">{this.props.text}</p>
            <Button type="primary" href={"/" + this.props.link}>
              Află mai multe
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pp-container">
          <div className="pp-content">
            <h2 className="pp-h">{this.props.title}</h2>
            <p className="pp-p">{this.props.text}</p>
            <Button type="primary" href={"/" + this.props.link}>
              Află mai multe
            </Button>
          </div>
          <div className="pp-img-container">
            <img
              className="pp-img"
              src={this.props.img}
              alt={this.props.alt}
            />
          </div>
        </div>
      );
    }
  }
}
