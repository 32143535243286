import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MainBlock from "./blocks/MainBlock/MainBlock";
import Specs from "./blocks/Specs/Specs";

class Material extends Component {
  state = {
    material: null,
  };

  componentDidMount = () => {
    this.setState({ material: this.props.material });
  };

  render() {
    const material = this.state.material;
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        {this.state.material && (
          <div>
            <MainBlock
              title={material.name}
              imgList={material.images}
              text={material.description}
              db={this.props.db}
              storage={this.props.storage}
              database={this.props.database}
              price={material.price}
              material={true}
              altList={material.altList}
            />
            <Specs material={true} list={material.specs} />
          </div>
        )}

        <Footer />
      </div>
    );
  }
}
export default Material;
