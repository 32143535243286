import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel } from "react-bootstrap";
import "./components/blocks/PresentCarousel/PresentCarousel.scss";
import "./components/Header.css";
import "./App.css";
import { Collapse,Button,Drawer, Input, Radio, Tooltip, message } from 'antd';
import Companies from "./components/blocks/Companies/Companies";
const { Panel } = Collapse;
const { TextArea } = Input

class App extends Component {
  state = {
    categories: this.props.categories,
    covers: [],
    mCovers: [],
    dCovers: [],
    quantity: 1,
    selectedOption: null,
    showOptions:false,
    showCalculator:true,
    contact: false
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    
    
    const docRef = this.props.db.collection("blocks").doc("_cover");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            dCovers: doc.data().img,
            mCovers: doc.data().mobile
          });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      }).then(() => this.setState({selectedOption:this.props.options[0]}));
    

  };

  optionChange(e) {
    this.setState({selectedOption: this.props.options[e.target.value], showOptions:false})


  }
  quantityChange(e) {
    this.setState({quantity: e.target.value})
  }
  selectOption = (index, option) => {
    var choices = this.state.selectedOption.constructor;
    var choice = this.state.selectedOption.constructor[index];
    for (let i = 0; i < choice.options.length; i++) {
      if (i === option) {
        choice.options[i].selected = true;
      } else {
        choice.options[i].selected = false;
      }
    }
    choices[index] = choice;
    var temp = this.state.selectedOption;
    temp.constructor = choices;
    this.setState({ selectedOption: temp });
  };
  
  
  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
   
  };

  sendEmail = () => {
    var id = Date.now()
    var all_ok = true;
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("number").value;
    const comments = document.getElementById("comments").value;


    if (name.length < 3) {
      message.warning("Numele nu este valid.");
      all_ok = false;
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      message.warning("Email-ul nu este valid.");
      all_ok = false;
      return;
    }
    if (phone.length < 9) {
      message.warning("Numarul de telefon nu este valid.");
      all_ok = false;
      return;
    }

    if (all_ok) {
      var email_text =
        "<b>Numele:</b> " +
        name +
        "<br/><b>Telefon: </b>" +
        phone +
        "<br/><b>Email: </b>" +
        email +
        "<br/><b>Comentarii: </b>" +
        comments +
        "<br/><b>Produsul: </b>" +
        this.state.selectedOption.title +
        ".<br/>";


          email_text += "<b>Lungimea: </b>" + this.state.length + " cm<br/><b>Lațimea: </b>" + this.state.width+ " cm<br/><b>Cantitatea: </b>" +this.state.quantity+'.<br/>'

        if(Array.isArray(this.state.selectedOption.constructor)){
          var cons = this.state.selectedOption.constructor
          for(let i=0; i<cons.length; i++){
            email_text += ("<b>"+ cons[i].name + "</b>: ")
            for(let j=0; j<cons[i].options.length; j++){
              if(cons[i].options[j].selected) {
                email_text += (cons[i].options[j].name +".<br/>")
              }
            }
          }
        }

      if (this.state.fileName) {
        let file = document.getElementById("files").files[0];
        let ref = this.props.storage.ref();
        var uploadTask = ref.child("0FilesFromClients/" + id).put(file);
        uploadTask.on("state_changed", () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            email_text += "<br/><b>File: </b>" + downloadURL + "<br/>";
            this.sendIt(email_text);
          });
        });
      } else {
        this.sendIt(email_text);
      }
      this.props.database
          .ref(id)
          .set({
            numele: name,
            email: email,
            telefon: phone,
            Produs: this.state.selectedOption.title,
            timestamp: id,
          })
    }
  };

  sendIt = (text) => {
    document.getElementById("name").value = " ";
    document.getElementById("email").value = " ";
    document.getElementById("number").value = " ";
    document.getElementById("comments").value = " ";
    this.props.db
      .collection("mail")
      .add({
        to: "marketing@totalgravura.md",
        message: {
          subject: "Cerere TotalGravura.md",
          text: text,
          html: text,
        },
      })
      .then(() => {
        this.setState({ contact: false });
        message.success(
          "Mulțumim! Un manager de vînzări va lua legatura cu dumneavoastră"
        );
      });
  };

  checkFile = () => {
    let fileName = document.getElementById("files").files[0].name;
    if (fileName) {
      this.setState({ fileName });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  render() {
    
    const showChoice = (index) => {
      var options = this.state.selectedOption.constructor[index].options
      for(let i=0; i<options.length; i++) {
        if(options[i].selected){
          return options[i].name
        }
      }
    }

    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="use-boots">
         {this.state.mobile ? (<Carousel
            nextIcon={<RightOutlined className="tg-icon" />}
            prevIcon={<LeftOutlined className="tg-icon" />}
          >
            {this.state.mCovers.map((image, index) => (
              <Carousel.Item key={index}>
                <img src={image} alt={this.props.title} className="tg-cover" />
              </Carousel.Item>
            ))}
          </Carousel>) : (<Carousel
            nextIcon={<RightOutlined className="tg-icon" />}
            prevIcon={<LeftOutlined className="tg-icon" />}
          >
            {this.state.dCovers.map((image, index) => (
              <Carousel.Item key={index}>
                <img src={image} alt={this.props.title} className="tg-cover" />
              </Carousel.Item>
            ))}
          </Carousel>)}
        </div>{this.state.selectedOption && 
        <div className='calculator'>
           {this.state.showCalculator && <div>
             <div className='calculator-size'>
        <select className='product-select' style={{ width: 300}} onChange={(e)=>this.optionChange(e)}>
              {this.props.options.map((item, index) => (
                <option  key={index} value={index}>{item.title}</option>
              ))}
        </select>

        <div style={{ display: "flex", justifyContent: "space-around", margin:'10px 0px 0px' }} className="sizing">
                <div style={{ display: "block", textAlign:'center' }}>
                  <label>Lațime: </label><br/>
                  <input value={this.state.width} style={{ width: "40px" }} placeholder="0" onChange={(e) =>this.setState({width: e.target.value})}/> cm
                </div>

                <div style={{ display: "block", textAlign:'center' }}>
                  <label>Lungime: </label><br/>
                  <input value={this.state.length} style={{ width: "40px" }} placeholder="0" onChange={(e) =>this.setState({length: e.target.value})}/> cm
                </div>

                <div style={{ display: "block" , textAlign:'center'}}>
                  <label>Cantitate: </label><br/>
                  <input style={{ width: "40px" }} value={this.state.quantity} onChange={(e) => this.quantityChange(e)}/>
                </div>
              </div>
<Button type='primary' className='price-button' onClick={() => this.setState({contact: true})}>Află preț</Button>

              </div>
  

{this.state.selectedOption && <div style={{textAlign:'center'}} >


        {this.state.selectedOption.constructor.length>1 && (

      <Button type='link' onClick={()=>this.setState({showOptions: !this.state.showOptions})}>{this.state.showOptions ? "Ascunde opțiunile" : "Arată opțiunile"}</Button>)}

      {this.state.mobile ? <div>
        {this.state.showOptions && (
             <Collapse accordion >
             {this.state.selectedOption.constructor.map((item, index) => ( 
                  <Panel   header={item.name+ " ― "  
                  +showChoice(index)
                } 
                  key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin:'0px auto',
                        overflow: "hidden",
                      }}
                    >
                      {item.options.map((option, i) => (
                        <div
                          onClick={() => this.selectOption(index, i)}
                          style={{
                            border: "1px solid #aaa",
                            borderRadius: "5px",
                            padding: "5px",
                            width: "115px",
                            margin: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ display: "flex" }}>
                              <Radio checked={option.selected}></Radio>
                              <p style={{fontSize:'11px', marginTop:'3px'}}>{option.name}</p>

                            </span>
                            <Tooltip title={option.info}>
                              <span>&#9432;</span>
                            </Tooltip>
                          </div>

                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              textAlign: "center",
                              margin: "5px",
                            }}
                            alt={item.name + " - " + option.name}
                            src={option.img}
                          ></img>
                        </div>
                      ))}
                    </div>
                  </Panel>))}
              </Collapse>
            )}
      </div> : <div>
      {this.state.showOptions && (
        <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
                 {this.state.selectedOption.constructor.map((item, index) => ( <div style={{margin:'0px 10px 10px'}}>       <Collapse style={{width:'300px'}} key={index}>
            
                  <Panel   header={item.name+ " ― "  
                  +showChoice(index)
                } 
                  key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.options.map((option, i) => (
                        <div
                          onClick={() => this.selectOption(index, i)}
                          style={{
                            border: "1px solid #aaa",
                            borderRadius: "5px",
                            padding: "5px",
                            width: "120px",
                            margin: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ display: "flex" }}>
                              <Radio checked={option.selected}></Radio>
                              <p style={{fontSize:'11px', marginTop:'3px'}}>{option.name}</p>

                            </span>
                            <Tooltip title={option.info}>
                              <span>&#9432;</span>
                            </Tooltip>
                          </div>

                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              textAlign: "center",
                              margin: "5px",
                            }}
                            alt={item.name + " - " + option.name}
                            src={option.img}
                          ></img>
                        </div>
                      ))}
                    </div>
                  </Panel>
              </Collapse></div>))}
        </div>

            )}
        </div>}
</div>}
            </div>}
<Button block style={{height:"20px",fontSize:"12px", lineHeight:'12px', backgroundColor:'#dedede'}}
 onClick={()=> this.setState({showCalculator:!this.state.showCalculator})}
 type="dashed">{this.state.showCalculator ? "Ascunde" : "Arată"}</Button>
</div>}
<Drawer
                title="Află preț"
                placement="right"
                closable={true}
                onClose={() => this.setState({contact: false})}
                visible={this.state.contact}
                width="320"
                bodyStyle={{ overflowX: "hidden" }}
              >
                {this.state.selectedOption && <div>
                <label>Produsul:</label><br/>
                <b>{this.state.selectedOption.title}</b><br/>
                <label>Mărimea:</label><br/>
                <b>{this.state.width}cm x {this.state.length}cm</b><br/>
                <label>Cantitatea:</label><br/>
                <b>{this.state.quantity}</b><br/>
                </div>}

                <label>Numele:</label>
                <br />
                <Input id="name"></Input>
                <br />
                <label>Număr de contact:</label>
                <br />
                <Input id="number"></Input>
                <br />
                <label>Email:</label>
                <br />
                <Input id="email"></Input>
                <br />
                <label>Alte detalii</label>
                <br />
                <TextArea rows={4} id="comments"></TextArea>
                <br />
                <div style={{ display: "block", height: "60px" }}>
                  <Button
                    block
                    style={{
                      margin: "10px auto",
                    }}
                    onClick={() => document.getElementById("files").click()}
                  >
                    Adaugă file...
                  </Button>
                  {this.state.fileName && (
                    <p
                      style={{
                        margin: "auto 0px",
                      }}
                    >
                      {this.state.fileName}
                    </p>
                  )}
                </div>
                <input
                  id="files"
                  type="file"
                  style={{ visibility: "hidden" }}
                  onChange={() => this.checkFile()}
                  tag="alege"
                ></input>

                <Button
                  block
                  type="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() => this.sendEmail()}
                >
                  Trimite
                </Button>
                
              </Drawer>

<h2 style={{textAlign:'center', marginTop:'20px'}} className='clients'>Clienții noștri</h2>
<Companies db={this.props.db} />
<h1 style={{textAlign:'center', marginTop:'20px'}} className='clients'>Total Gravura - Servicii profesionale publicitare</h1>
                    <a href="viber://chat?number=%2B37368605106" target="_blank" title="Scrieți în Viber" rel="noopener noreferrer">
    <div class="viber-button"></div></a>
        <div className="blocks-container">
          {this.props.list.map((item) => (
            <div className="home-block" key={item.id}>
              <a href={"/" + item.id}>
                <img
                  alt={item.name}
                  className="dropdown-image"
                  src={item.url}
                />
                <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                  {item.name}
                </h2>
              </a>
              <h2 style={{ fontSize: "14px", fontWeight: "400" }}>
                {item.description}
              </h2>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
export default App;
