import React, { Component } from "react";
import "./MainBlock.css";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { Button, Collapse, Tooltip, Drawer, Input, message, Radio } from "antd";

const { TextArea } = Input;
const { Panel } = Collapse;

export default class MainBlock extends Component {
  state = {
    img: this.props.imgList[0],
    imgIndex: 0,
    contact: false,
    constructor: this.props.constructor,
    size: this.props.size,
    quantity: 1,
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  quantityChange(e) {
    this.setState({ quantity: e.target.value });
  }

  setImage = (img, index) => {
    this.setState({ img: img, imgIndex: index });
  };

  prevImg = () => {
    var temp;
    if (this.state.imgIndex === 0) {
      temp = this.props.imgList.length - 1;
    } else {
      temp = this.state.imgIndex - 1;
    }
    this.setImage(this.props.imgList[temp], temp);
  };

  nextImg = () => {
    var temp;
    if (this.state.imgIndex === this.props.imgList.length - 1) {
      temp = 0;
    } else {
      temp = this.state.imgIndex + 1;
    }
    this.setImage(this.props.imgList[temp], temp);
  };
  showContact = () => {
    this.setState({ contact: true });
  };

  closeContact = () => {
    this.setState({ contact: false });
  };

  sendEmail = () => {
    var all_ok = true;
    var id = Date.now();
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("number").value;
    const comments = document.getElementById("comments").value;
    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;

    if (name.length < 3) {
      message.warning("Numele nu este valid.");
      all_ok = false;
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      message.warning("Email-ul nu este valid.");
      all_ok = false;
      return;
    }
    if (phone.length < 9) {
      message.warning("Numarul de telefon nu este valid.");
      all_ok = false;
      return;
    }

    if (all_ok) {
      var email_text =
        "<b>Numele:</b> " +
        name +
        "<br/><b>Telefon: </b>" +
        phone +
        "<br/><b>Email: </b>" +
        email +
        "<br/><b>Comentarii: </b>" +
        comments +
        "<br/><b>Produsul: </b>" +
        url +
        ".<br/>";

      if (this.state.size) {
        email_text +=
          "<b>Lungimea: </b>" +
          document.getElementById("lungimea").value +
          "<br/><b>Lațimea: </b>" +
          document.getElementById("latimea").value +
          "<br/><b>Cantitatea: </b>" +
          this.state.quantity +
          ".<br/>";
      }
      if (Array.isArray(this.state.constructor)) {
        var cons = this.state.constructor;
        for (let i = 0; i < cons.length; i++) {
          email_text += "<b>" + cons[i].name + "</b>: ";
          for (let j = 0; j < cons[i].options.length; j++) {
            if (cons[i].options[j].selected) {
              email_text += cons[i].options[j].name + ".<br/>";
            }
          }
        }
      }

      if (this.state.fileName) {
        let file = document.getElementById("files").files[0];
        let ref = this.props.storage.ref();
        var uploadTask = ref.child("0FilesFromClients/" + id).put(file);
        uploadTask.on("state_changed", () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            email_text += "<br/><b>File: </b>" + downloadURL + "<br/>";
            this.sendIt(email_text);
          });
        });
      } else {
        this.sendIt(email_text);
      }
      this.props.database.ref(id).set({
        numele: name,
        email: email,
        telefon: phone,
        Produs: this.state.selectedOption.title,
        timestamp: id,
      });
    }
  };

  sendIt = (text) => {
    document.getElementById("name").value = " ";
    document.getElementById("email").value = " ";
    document.getElementById("number").value = " ";
    document.getElementById("comments").value = " ";
    this.props.db
      .collection("mail")
      .add({
        to: "marketing@totalgravura.md",
        message: {
          subject: "Cerere TotalGravura.md",
          text: text,
          html: text,
        },
      })
      .then(() => {
        this.setState({ contact: false });
        message.success(
          "Mulțumim! Un manager de vînzări va lua legatura cu dumneavoastră"
        );
      });
  };

  checkFile = () => {
    let fileName = document.getElementById("files").files[0].name;
    if (fileName) {
      this.setState({ fileName });
    }
  };

  selectOption = (index, option) => {
    var choices = this.state.constructor;
    var choice = this.state.constructor[index];
    for (let i = 0; i < choice.options.length; i++) {
      if (i === option) {
        choice.options[i].selected = true;
      } else {
        choice.options[i].selected = false;
      }
    }
    choices[index] = choice;
    this.setState({ constructor: choices });
  };

  render() {
    var gridList = {
      width: 120,
      height: 400,
      margin: "0px 10px 0px 0px",
      overflowY: "scroll",
    };

    const showChoice = (index) => {
      var options = this.state.constructor[index].options;
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          return options[i].name;
        }
      }
    };

    if (this.state.mobile) {
      gridList = {
        width: 300,
        height: 60,
        margin: "20px 0px",
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        overflowX: "scroll",
        overflowY: "hidden",
      };
      return (
        <div>
          <h2 className="main-h">{this.props.title}</h2>
          <h3 className="main-sh">{this.props.subtitle}</h3>
          <div className="main-root">
            <div className="main-img-cont">
              <div
                style={{ backgroundImage: "url(" + this.state.img + ")" }}
                className="main-image"
              >
                <button
                  className="arrow-btn"
                  onClick={() => this.prevImg()}
                  style={{ height: "100%" }}
                >
                  &#8249;
                </button>
                <button
                  className="arrow-btn"
                  onClick={() => this.nextImg()}
                  style={{ float: "right", height: "100%" }}
                >
                  &#8250;
                </button>
              </div>
              <GridList cellHeight={58} style={gridList} cols={3.5}>
                {this.props.imgList.map((tile, index) => (
                  <GridListTile
                    key={index}
                    cols={tile.cols || 1}
                    onClick={() => this.setImage(tile, index)}
                  >
                    {index === this.state.imgIndex ? (
                      <img
                        src={tile}
                        alt={this.props.title}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={tile}
                        alt={this.props.title}
                        style={{ cursor: "pointer" }}
                        className="grayscale"
                      />
                    )}
                  </GridListTile>
                ))}
              </GridList>
            </div>
            <div className="text-cont">
              <p>{this.props.text}</p>
              {this.props.material && <b>Prețul: {this.props.price}</b>}
              <br />
              {!this.props.categoryPage && (
                <a href="#description">Află mai multe</a>
              )}
              <br />
              {this.state.size && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0px",
                  }}
                >
                  <div style={{ display: "block", textAlign: "center" }}>
                    <label>Lațimea: </label>
                    <input style={{ width: "80px" }} placeholder="0 cm" />{" "}
                  </div>

                  <div style={{ display: "block", textAlign: "center" }}>
                    {" "}
                    <label>Lungimea: </label>
                    <input style={{ width: "80px" }} placeholder="0 cm" />{" "}
                  </div>

                  <div style={{ display: "block", textAlign: "center" }}>
                    <label>Cantitatea: </label>
                    <input
                      style={{ width: "80px" }}
                      value={this.state.quantity}
                      onChange={(e) => this.quantityChange(e)}
                    />{" "}
                  </div>
                </div>
              )}
              {Array.isArray(this.state.constructor) && (
                <Collapse accordion>
                  {this.state.constructor.map((item, index) => (
                    <Panel
                      header={item.name + " ― " + showChoice(index)}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          overflow: "hidden",
                        }}
                      >
                        {item.options.map((option, i) => (
                          <div
                            onClick={() => this.selectOption(index, i)}
                            style={{
                              border: "1px solid #aaa",
                              borderRadius: "5px",
                              padding: "5px",
                              width: "115px",
                              margin: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span style={{ display: "flex" }}>
                                <Radio checked={option.selected}></Radio>
                                <p
                                  style={{ fontSize: "11px", marginTop: "5px" }}
                                >
                                  {option.name}
                                </p>
                              </span>
                              <Tooltip title={option.info}>
                                <span>&#9432;</span>
                              </Tooltip>
                            </div>

                            <img
                              style={{
                                width: "80px",
                                height: "80px",
                                textAlign: "center",
                                margin: "5px",
                              }}
                              alt={item.name + " - " + option.name}
                              src={option.img}
                            ></img>
                          </div>
                        ))}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              )}
              {!this.props.categoryPage && (
                <div>
                  {this.props.material ? (
                    <Button
                      type="primary"
                      className="main-contact"
                      size="large"
                      onClick={() => this.showContact()}
                    >
                      Contactează-ne
                    </Button>
                  ) : (
                    <>
                    <Button
                      type="primary"
                      className="main-contact"
                      size="large"
                      onClick={() => this.showContact()}
                    >
                      Află preț
                    </Button>
                      <a href="viber://chat?number=%2B37368605106" target="_blank" title="Scrieți în Viber" rel="noopener noreferrer">
                      <div class="viber-button"></div></a>
                      </>
                  )}
                </div>
              )}
              <Drawer
                title="Contacte"
                placement="right"
                closable={true}
                onClose={() => this.closeContact()}
                visible={this.state.contact}
                width="320"
                bodyStyle={{ overflowX: "hidden" }}
              >
                <label>Numele:</label>
                <br />
                <Input id="name"></Input>
                <br />
                <label>Număr de contact:</label>
                <br />
                <Input id="number"></Input>
                <br />
                <label>Email:</label>
                <br />
                <Input id="email"></Input>
                <br />
                <label>Cu ce vă putem ajuta?</label>
                <br />
                <TextArea rows={4} id="comments"></TextArea>
                <br />
                <div style={{ display: "block", height: "60px" }}>
                  <Button
                    block
                    style={{
                      margin: "10px auto",
                    }}
                    onClick={() => document.getElementById("files").click()}
                  >
                    Adaugă file...
                  </Button>
                  {this.state.fileName && (
                    <p
                      style={{
                        margin: "auto 0px",
                      }}
                    >
                      {this.state.fileName}
                    </p>
                  )}
                </div>
                <input
                  id="files"
                  type="file"
                  style={{ visibility: "hidden" }}
                  onChange={() => this.checkFile()}
                  tag="alege"
                ></input>

                <Button
                  block
                  type="primary"
                  style={{ marginTop: "20px" }}
                  onClick={() => this.sendEmail()}
                >
                  Trimite
                </Button>
                <div style={{ display: "block", textAlign: "center" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.465087302879!2d28.87260238813909!3d46.98407751359416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97945ba4cfec3%3A0x90d62378bb2dc7e9!2sTotal%20Gravura!5e0!3m2!1sen!2s!4v1611301477966!5m2!1sen!2s"
                    width="270"
                    height="250"
                    frameborder="0"
                    title="maps"
                    aria-hidden="false"
                    tabindex="0"
                    style={{ marginTop: "20px" }}
                  ></iframe>
                  <div style={{ marginLeft: "0px" }}>
                    <a
                      className="footer-link"
                      href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
                    >
                      str. Cernăuți 20A Chișinău, Moldova
                    </a>
                    <a
                      href="mailto:office@totalgravura.md"
                      className="footer-link"
                    >
                      office@totalgravura.md
                    </a>

                    <Button
                      style={{ margin: "auto auto" }}
                      ghost
                      href="tel:+37322558656"
                      type="primary"
                      size="large"
                    >
                      022 55 86 56
                    </Button>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h2 className="main-h">{this.props.title}</h2>
        <h2 className="main-sh">{this.props.subtitle}</h2>
        <div className="main-root">
          <div className="main-img-cont">
            {this.props.imgList.length > 1 && (
              <GridList cellHeight={80} style={gridList} cols={1}>
                {this.props.imgList.map((tile, index) => (
                  <GridListTile
                    key={index}
                    cols={tile.cols || 1}
                    onClick={() => this.setImage(tile, index)}
                  >
                    {index === this.state.imgIndex ? (
                      <img
                        src={tile}
                        alt={this.props.title}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        src={tile}
                        alt={this.props.title}
                        style={{ cursor: "pointer" }}
                        className="grayscale"
                      />
                    )}
                  </GridListTile>
                ))}
              </GridList>
            )}
            <div
              style={{ backgroundImage: "url(" + this.state.img + ")" }}
              className="main-image"
            >
              {this.props.imgList.length > 1 && (
                <button
                  className="arrow-btn"
                  onClick={() => this.prevImg()}
                  style={{ height: "100%" }}
                >
                  &#8249;
                </button>
              )}
              {this.props.imgList.length > 1 && (
                <button
                  className="arrow-btn"
                  onClick={() => this.nextImg()}
                  style={{ float: "right", height: "100%" }}
                >
                  &#8250;
                </button>
              )}
            </div>
          </div>
          <div className="text-cont">
            <p>{this.props.text}</p>
            {this.props.material && <b>Prețul: {this.props.price}</b>}
            <br />
            {!this.props.categoryPage && (
              <a href="#description">Află mai multe</a>
            )}
            <br />
            {this.state.size && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0px",
                }}
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <label>Lațimea: </label>
                  <input
                    id="latimea"
                    style={{ width: "80px" }}
                    placeholder="0 cm"
                  />{" "}
                </div>

                <div style={{ display: "block", textAlign: "center" }}>
                  {" "}
                  <label>Lungimea: </label>
                  <input
                    id="lungimea"
                    style={{ width: "80px" }}
                    placeholder="0 cm"
                  />{" "}
                </div>

                <div style={{ display: "block", textAlign: "center" }}>
                  <label>Cantitatea: </label>
                  <input
                    style={{ width: "80px" }}
                    value={this.state.quantity}
                    onChange={(e) => this.quantityChange(e)}
                  />{" "}
                </div>
              </div>
            )}
            {Array.isArray(this.state.constructor) && (
              <Collapse accordion>
                {this.state.constructor.map((item, index) => (
                  <Panel
                    header={item.name + " ― " + showChoice(index)}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.options.map((option, i) => (
                        <div
                          onClick={() => this.selectOption(index, i)}
                          style={{
                            border: "1px solid #aaa",
                            borderRadius: "5px",
                            padding: "5px",
                            width: "112px",
                            margin: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ display: "flex" }}>
                              <Radio checked={option.selected}></Radio>
                              <p style={{ fontSize: "11px", marginTop: "5px" }}>
                                {option.name}
                              </p>
                            </span>
                            <Tooltip title={option.info}>
                              <span>&#9432;</span>
                            </Tooltip>
                          </div>

                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              textAlign: "center",
                              margin: "5px",
                            }}
                            alt={item.name + " - " + option.name}
                            src={option.img}
                          ></img>
                        </div>
                      ))}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            )}
            {!this.props.categoryPage && (
              <div>
                {this.props.material ? (
                  <Button
                    type="primary"
                    className="main-contact"
                    size="large"
                    onClick={() => this.showContact()}
                  >
                    Contactează-ne
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="main-contact"
                    size="large"
                    onClick={() => this.showContact()}
                  >
                    Află preț
                  </Button>
                )}
              </div>
            )}

            <Drawer
              title="Contacte"
              placement="right"
              closable={true}
              onClose={() => this.closeContact()}
              visible={this.state.contact}
              width="640"
              bodyStyle={{ overflowX: "hidden" }}
            >
              <label>Numele:</label>
              <br />
              <Input id="name"></Input>
              <br />
              <label>Număr de contact:</label>
              <br />
              <Input id="number"></Input>
              <br />
              <label>Email:</label>
              <br />
              <Input id="email"></Input>
              <br />
              <label>Cu ce vă putem ajuta?</label>
              <br />
              <TextArea rows={4} id="comments"></TextArea>
              <br />
              <div style={{ display: "flex", height: "60px" }}>
                <Button
                  style={{
                    margin: "auto 0px",
                  }}
                  onClick={() => document.getElementById("files").click()}
                >
                  Adaugă file...
                </Button>
                {this.state.fileName && (
                  <p
                    style={{
                      margin: "auto 10px",
                    }}
                  >
                    {this.state.fileName}
                  </p>
                )}
              </div>
              <input
                id="files"
                type="file"
                style={{ visibility: "hidden" }}
                onChange={() => this.checkFile()}
                tag="alege"
              ></input>

              <Button
                block
                type="primary"
                style={{ marginTop: "0px" }}
                onClick={() => this.sendEmail()}
              >
                Trimite
              </Button>
              <div style={{ display: "block" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.465087302879!2d28.87260238813909!3d46.98407751359416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97945ba4cfec3%3A0x90d62378bb2dc7e9!2sTotal%20Gravura!5e0!3m2!1sen!2s!4v1611301477966!5m2!1sen!2s"
                  width="590"
                  height="300"
                  frameborder="0"
                  title="maps"
                  aria-hidden="false"
                  tabindex="0"
                  style={{ marginTop: "20px" }}
                ></iframe>
                <div style={{ marginLeft: "0px" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <a
                        className="footer-link"
                        href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
                      >
                        str. Cernăuți 20A Chișinău, Moldova
                      </a>
                      <a
                        href="mailto:office@totalgravura.md"
                        className="footer-link"
                      >
                        office@totalgravura.md
                      </a>
                    </div>
                    <Button
                      style={{ margin: "auto auto" }}
                      ghost
                      href="tel:+37322558656"
                      type="primary"
                      size="large"
                    >
                      022 55 86 56
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    );
  }
}
