/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { Dropdown, Drawer, Menu, Divider, Button } from "antd";
import "./Header.css";
import logo from "../img/logo.webp";
import materials_icon from '../img/membrane.webp'
import { MenuOutlined,RightOutlined, LeftOutlined, CommentOutlined,UpOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;


export default class Header extends Component {
  state = {
    servicii: {},
    categories: [],
    hovered: {},
    news: "",
    announcements: null,
    visible: false,
    contact: false,
  };

  componentDidMount = () => {
    const scrollFunction = () => {
      var upBtn = document.getElementById("upBtn");
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        upBtn.style.display = "block";
      } else {
        upBtn.style.display = "none";
      }
    }
    window.onscroll = function() {scrollFunction()};
    this.setState({
      categories: this.props.categories,
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    const docRef = this.props.db.collection("blocks").doc("_news");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            news: doc.data().arr,
          });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

      const docRefs = this.props.db.collection("blocks").doc("_announcements");

    docRefs
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            announcements: doc.data().arr,
          });
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });


  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  setServicii = (product) => {
    this.setState({
      servicii: product,
    });
  };

  getMenu = (id) => {
    var categories = this.state.categories;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].name === id) {
        const list = categories[i].data.subs.map((data) => (
          <div key={data.name}>
            <div>
              <h4>{data.name}</h4>
              {data.product.map((product) => (
                <li key={product.id}>
                  <a
                    onMouseEnter={() => this.onLinkHover(product)}
                    href={"/" + product.id}
                  >
                    {product.name}
                  </a>
                </li>
              ))}
              <br />
            </div>
          </div>
        ));
        return (
          <div className="dropdown">
            <div className="dropdown-menu">{list}</div>
            <div className="dropdown-image-container">
              <img
                alt={this.state.hovered.name}
                className="dropdown-image"
                src={this.state.hovered.url}
              />
              <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                {this.state.hovered.name}
              </h2>
              <h3 style={{ fontSize: "14px", fontWeight: "400" }}>
                {this.state.hovered.description}
              </h3>
            </div>
          </div>
        );
      }
    }
  };

  onLinkHover = (product) => {
    this.setState({
      hovered: product,
    });
  };
  showDrawer = () => {
    this.setState({ visible: true });
  };
  onClose = () => {
    this.setState({ visible: false });
  };

  showContact = () => {
    this.setState({ contact: true });
  };

  closeContact = () => {
    this.setState({ contact: false });
  };


  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getPadding(str) {
    if(str.length > 77){
      return "20px 60px"
    }return "30px 60px"
  }


  render() {
    const categories = this.state.categories;
    if (this.state.mobile) {
      return (
        <header>
          <div id="header-top">
            <a href="/">
              <img
                className="logo"
                alt="Total Gravura - printare, gravare, taiere."
                src={logo}
              ></img>
            </a>
          </div>
          <div id="header-bar">
            <div
              id="header-menu"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <MenuOutlined
                onClick={() => this.showDrawer()}
                style={{ fontSize: "26px", color: "#000", lineHeight: "48px" }}
              />
              <Drawer
                title="Total Gravura"
                placement="left"
                closable={true}
                onClose={() => this.onClose()}
                visible={this.state.visible}
                width="276"
                bodyStyle={{ overflowX: "hidden" }}
              >
                <Menu mode="inline" style={{ width: 256, overflowX: "hidden" }}>
                  {categories.map((cat) => (
                    <SubMenu
                      key={cat.name}
                      title={cat.name}
                      icon={
                        <img
                          style={{
                            height: "14px",
                            margin: "auto 4px 4px -2px",
                          }}
                          alt="Total Gravura - publicitate stradală, imprimare de bannere - уличная реклама, печать баннеров"
                          src={cat.data.icon}
                        />
                      }
                    >
                      {cat.data.subs.map((item) => (
                        <>
                          <Menu.Item
                            key={item.name}
                            disabled
                            className="menu-item-sub"
                            style={{ fontWeight: "bold", cursor: "default" }}
                          >
                            {item.name}
                          </Menu.Item>
                          {item.product.map((product) => (
                            <Menu.Item key={product.id} onClick>
                              <a href={"/" + product.id}>{product.name}</a>
                            </Menu.Item>
                          ))}
                          <Divider />
                        </>
                      ))}
                    </SubMenu>
                  ))}
                  <Menu.Item 
                  icon={
                    <img
                    style={{
                      height: "14px",
                      margin: "auto 4px 4px -2px",
                    }}
                    src={materials_icon} alt='Materiale de vinzare'></img>
                  }>
                   <a href='/materiale'>Materiale</a> 
                  </Menu.Item>
                </Menu>
              </Drawer>
              <Dropdown
                overlay={
                  <div className="support-container">
                    <div className="support-small">
                      <b>Suntem aici pentru a vă ajuta!</b>
                      <a href="/contact" className="support-link">
                        Contacteaza-ne
                      </a>
                      <a
                        href="mailto:office@totalgravura.md"
                        className="support-link"
                      >
                        office@totalgravura.md
                      </a>
                      <a
                        className="support-link"
                        href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
                      >
                        str. Cernăuți 20A Chișinău, Moldova
                      </a>{" "}
                      <Button
                        href="tel:+37322265850"
                        type="primary"
                        size="large"
                        style={{ margin: "10px 3px" }}
                      >
                        022 265 850
                      </Button>
                      <br></br>
                      <i>Luni - Vineri: 9:00 - 18:00</i>
                    </div>
                  </div>
                }
              >
                <a
                  className="head-support"
                  href="/"
                  onClick={(e) => e.preventDefault()}
                >
                  <CommentOutlined /> Support
                </a>
              </Dropdown>
            </div>
          </div>
          {this.state.news !== "" && (
            <div className="header-news">{this.state.news}</div>
          )}
                <UpOutlined className='up-button' onClick={() =>this.topFunction()} id="upBtn"/>


        </header>
      );
    }
    return (
      <header>
        <div id="header-top">
          <a href="/">
            <img
              className="logo"
              alt="Total Gravura - printare, gravare, taiere."
              src={logo}
            ></img>
          </a>
       {this.state.announcements && <div className="use-boots">
          <Carousel className="six-00"
            interval={3000}
            nextIcon={<RightOutlined className="move-icon" />}
            prevIcon={<LeftOutlined className="move-icon" />}
          >
            {this.state.announcements.map((item, index) => (
               <Carousel.Item key={index} >
                <div id={"slide"+index} style={{width:"600px", height:'100px', textAlign:'center', padding:this.getPadding(item),}}>
                {item}
                </div>
              </Carousel.Item>
            ))}
            
             
          </Carousel>
          </div>}
          <Dropdown
            overlay={
              <div className="support-container">
                <div className="support-small">
                  <b>Suntem aici pentru a vă ajuta!</b>
                  <a href="/contact" className="support-link">
                    Contacteaza-ne
                  </a>
                  <a
                    href="mailto:office@totalgravura.md"
                    className="support-link"
                  >
                    office@totalgravura.md
                  </a>
                  <a
                    className="support-link"
                    href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
                  >
                    str. Cernăuți 20A Chișinău, Moldova
                  </a>{" "}
                  <Button
                    href="tel:+37322265850"
                    type="primary"
                    size="large"
                    style={{ margin: "10px" }}
                  >
                    022 265 850
                  </Button>
                  <br></br>
                  <i>Luni - Vineri: 9:00 - 18:00</i>
                </div>
                <div className="support-small">
                  <b>Link-uri ajutătoare</b>
                  <a href="/servicii-design" className="footer-link">
                    Design Profesional
                  </a>
                  <a href="/rechizite" className="footer-link">
                    Rechizite
                  </a>
                  <a href="/despre" className="footer-link">
                    Despre Noi
                  </a>
                  <a href="/avantaje" className="footer-link">
                    Avantajele Noastre
                  </a>
                  <a href="/parteneri" className="footer-link">
                    Pentru Parteneri
                  </a>
                  <a href="/tehnica" className="footer-link">
                    Baza Tehnică
                  </a>
                  <a href="/blog" className="footer-link">
                    Blog
                  </a>
                </div>
              </div>
            }
          >
            <a
              className="head-support"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <CommentOutlined /> Support
            </a>
          </Dropdown>
        </div>
        <div id="header-bar">
          <div id="header-menu">
            {categories.map((cat) => (
              <Dropdown
                key={cat.name}
                overlay={this.getMenu(cat.name)}
                onVisibleChange={() =>
                  this.onLinkHover(cat.data.subs[0].product[0])
                }
              >
                <a
                  className="ant-dropdown-link"
                  href={"/"+cat.url}
                >
                  {cat.name}
                </a>
              </Dropdown>
            ))}
            <Dropdown
              overlay={
                <div className="dropdown">
                  <div className="dropdown-menu">
                    <a href="/materiale">
                      <h4>Materiale de vânzare.</h4>
                    </a>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      Compania "Total Gravura" oferă diverse 
                    </p>
                    <p style={{ margin: "0px", padding: "0px" }}>
                     materiale publicitare moderne de înaltă 
                    </p>
                    <p style={{ margin: "0px 0px 20px", padding: "0px" }}>
                      calitate pentru producția de publicitate:
                    </p>
                    <ul>
                      <li>Sticlă organică - transparentă, colorată </li>
                      <li>Aluminiu pentru sublimare, gravare, print UV </li>
                      <li>Oracal autoadeziv, one way vision</li>
                    </ul>

                    <br />
                  </div>
                  <div className="dropdown-image-container">
                    <a href="/materiale">
                      {" "}
                      <img
                        alt="Materiale de vinzare"
                        className="dropdown-image"
                        src="https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/materiale%2Fcover.webp?alt=media&token=fae69477-9e37-4db4-8ad6-4b2186726404"
                      />
                    </a>
                    <a href="/materiale">
                      {" "}
                      <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                        Materiale de vânzare
                      </h2>
                    </a>
                  </div>
                </div>
              }
            >
              <a className="ant-dropdown-link" href="/materiale">
                Materiale
              </a>
            </Dropdown>
          </div>
        </div>
        {this.state.news !== "" && (
          <div className="header-news">{this.state.news}</div>
        )}
        <UpOutlined className='up-button' onClick={() =>this.topFunction()} id="upBtn"/>
        {/* <button className='up-button' onClick={() =>this.topFunction()} id="upBtn">&#8679;</button> */}

      </header>
    );
  }
}
