import React, { Component } from "react";
import "./Top.scss";
import top from "../../../img/100.webp";
import fast from "../../../img/fast.webp";
import award from "../../../img/award.webp";
import pers from "../../../img/personalizare.webp";
export default class Top extends Component {
  render() {
    return (
      <div>
        <h1 className="dc">De ce Total Gravura este cea mai bună alegere?</h1>
        <div className="top-container">
          <div className="top-mini">
            <img src={fast} alt="Printare rapidă" className="top-img"></img>
            <h3>Producția într-o singură zi</h3>
            <p>
              Comandă pînă la ora 14:00 iar noi vom avea grijă ca produsul tău
              sa fie gata a doua zi.
            </p>
          </div>
          <div className="top-mini">
            <img
              src={award}
              alt="Clienți mulțumiți în sfera publicitară"
              className="top-img"
            ></img>
            <h3>Peste 26.000 de clienți mulțumiți</h3>
            <p>
              Fondată in 2005, compania noastră colaboreaza cu cele mai mare
              branduri din țară.
            </p>
          </div>
          <div className="top-mini">
            <img
              src={top}
              alt="Top printare, gravare, taiere."
              className="top-img"
            ></img>
            <h3>100% Satisfacție Garantată</h3>
            <p>
              Compania noastră este cunoscută pe piață din R.Moldova pentru
              calitatea produselor executate și rapiditatea cu care facem acest
              lucru.
            </p>
          </div>
          <div className="top-mini">
            <img
              src={pers}
              alt="Personalizare cadouri, produse."
              className="top-img"
            ></img>
            <h3>Personalizare completă</h3>
            <p>
              Diferite forme, materiale și mărimi pentru a primi exact produsul
              dorit.
            </p>
          </div>
        </div>{" "}
      </div>
    );
  }
}
