import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import ImageList from '../blocks/ImageList/ImageList.js'

export default class Blog extends Component {
  state = {
    list:[]
  }
  componentDidMount =()=> {
    var list =[]
    for(let i=0; i<this.props.posts.length; i++){
      list.push({
        title:this.props.posts[i].data.title,
        text:this.props.posts[i].data.text,
        img:this.props.posts[i].data.img,
        url:this.props.posts[i].id,
      })
    }
    this.setState({list:list})
  }
    render() {
        return (
          <div>
            <Header db={this.props.db} categories={this.props.categories} />
            <div
        style={{
          maxWidth:"1500px",
          margin:"0px auto"
        }}>
                <ImageList blog={true} list={this.state.list} />
</div>
            <Footer />
          </div>
        );
    }
}
