import { Col, Row, Button } from "antd";
import React, { Component } from "react";
import "./Footer.css";
import logo from '../img/logo.webp'
import edu from '../img/EduCards.webp'
import print from '../img/print.svg'


import insta from '..//img/insta.webp'
import fb from '..//img/fb.webp'
import tik from '..//img/tiktok.webp'
import linkedin from '..//img/linkedin.webp'
import yt from '..//img/yt.webp'

export default class Footer extends Component {

  state = {
    mobile : ''
  }

  componentDidMount = () => {
    // openKey.addEventListener('keys', this.setState({openKeys: openKey}))
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {

    if(this.state.mobile) {
      return (
        <div>
 <div style={{ textAlign: "center" }} className="footer-cont">

          <Col span="24">
            <div style={{ textAlign: "center", margin: "auto" }}>
              <a
                class="futerLink"
                title="Instagram"
                href="https://www.instagram.com/totalgravura/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="Instagram Total Gravura - Materiale POS, marketing, publicitate"
                  src={insta}
                />
              </a>
              <a
                class="futerLink"
                href="/"
                title="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="Linkedin - Total Gravura - POS материалы, маркетинг, реклама"
                  src={linkedin}
                />
              </a>
              <a
                class="futerLink"
                title="Facebook"
                href="https://www.facebook.com/totalgravura.md"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="Facebook Companiei Total Gravura"
                  src={fb}
                />
              </a>
              <a
                class="futerLink"
                href="https://youtube.com/channel/UCMpUVp2AurxaE6IzxZM7kyg"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
              >
                <img
                  class="futerImg"
                  alt="Youtube firma Total Gravura"
                  src={yt}
                />
              </a>

              <a
                class="futerLink"
                href="https://www.tiktok.com/@total_gravura?lang=en"
                title="TikTok"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="tiktok servicii publicitare Total Gravura"
                  src={tik}
                />
              </a>
            </div>
            <Button
              href="tel:022558656"
              type="primary"
              size="large"
              style={{ margin: "10px" }}
            >
              022 55 86 56
            </Button>
            <a href="mailto:office@totalgravura.md" className="footer-link">
              office@totalgravura.md
            </a>
            <a
              className="footer-link"
              href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
            >
              str. Cernăuți 20A Chișinău, Moldova
            </a>
          </Col>
          <Col span="24">
            <h4>Ajutor</h4>
            <a href="/contact" className="footer-link">
              Contacteaza-ne
            </a>
            <a href="/servicii-design" className="footer-link">
              Design Profesional
            </a>
            <a href="/rechizite" className="footer-link">
              Rechizite
            </a>
            <a href="/blog" className="footer-link">
              Blog
            </a>
          </Col>
          <Col span="24">
            <h4>Companie</h4>
            <a href="/despre" className="footer-link">
              Despre Noi
            </a>
            <a href="/avantaje" className="footer-link">
              Avantajele Noastre
            </a>
            <a href="/parteneri" className="footer-link">
              Pentru Parteneri
            </a>
            <a href="/tehnica" className="footer-link">
              Baza Tehnică
            </a>
          </Col>

        <div className='related'>
          <a target="_blank" rel="noreferrer" href="https://edu-cards.com">
            <img className="related" src={edu} alt="EduCards Moldova" />
          </a>
          <span className="top-related">
            <img src={logo} alt="Total Gravura Moldova - printare, taiere, banere" />
            <p>&copy; 2005 - 2021</p>
          </span>
          <a target="_blank" rel="noreferrer" href="http://tgprint.md">
            <img className="related" src={print} alt="TG-Print Moldova" />
          </a>
        </div>
      </div>
        </div>
      )
    }

    return (
      <div style={{ textAlign: "center" }} className="footer-cont">
        <Row>
          <Col span="8">
            <h4>Ajutor</h4>
            <a href="/contact" className="footer-link">
              Contacteaza-ne
            </a>
            <a href="/servicii-design" className="footer-link">
              Design Profesional
            </a>
            <a href="/rechizite" className="footer-link">
              Rechizite
            </a>
            <a href="/blog" className="footer-link">
              Blog
            </a>
          </Col>
          <Col span="8">
            <div style={{ textAlign: "center", margin: "auto" }}>
              <a
                class="futerLink"
                title="Instagram"
                href="https://www.instagram.com/totalgravura/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="instagram Total Gravura - agenție de publicitate - агентство по изготовлению рекламы "
                  src={insta}
                />
              </a>
              <a
                class="futerLink"
                href="https://www.linkedin.com/in/total-gravura-7591741a0/"
                title="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="linkedin Total Gravura - bannere, autocolante, steaguri, design, imprimare, tăiere, gravură, cărți de vizită, medalii, insigne, trofee, cupe"
                  src={linkedin}
                />
              </a>
              <a
                class="futerLink"
                title="Facebook"
                href="https://www.facebook.com/totalgravura.md"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="Facebook Total Gravura - баннеры, стикеры, флаги, дизайн, печать, резка, гравировка, визитки, медали, значки, трофеи, кубки"
                  src={fb}
                />
              </a>
              <a
                class="futerLink"
                href="https://youtube.com/channel/UCMpUVp2AurxaE6IzxZM7kyg"
                target="_blank"
                rel="noreferrer"
                title="YouTube"
              >
                <img
                  class="futerImg"
                  alt="Youtube - Total Gravura - orice tip de publicitate"
                  src={yt}
                />
              </a>

              <a
                class="futerLink"
                href="https://www.tiktok.com/@total_gravura?lang=en"
                title="TikTok"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="futerImg"
                  alt="TikTok Total Gravura - insigne, insigne, plăcuțe - значки, бейджи, таблички"
                  src={tik}
                />
              </a>
            </div>
            <Button
              href="tel:+37322265850"
              type="primary"
              size="large"
              style={{ margin: "10px" }}
            >
              022 265 850
            </Button>
            <a href="mailto:office@totalgravura.md" className="footer-link">
              office@totalgravura.md
            </a>
            <a
              className="footer-link"
              href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
            >
              str. Cernăuți 20A Chișinău, Moldova
            </a>
          </Col>
          <Col span="8">
            <h4>Companie</h4>
            <a href="/despre" className="footer-link">
              Despre Noi
            </a>
            <a href="/avantaje" className="footer-link">
              Avantajele Noastre
            </a>
            <a href="/parteneri" className="footer-link">
              Pentru Parteneri
            </a>
            <a href="/tehnica" className="footer-link">
              Baza Tehnică
            </a>
          </Col>
        </Row>
        <div className="related">
          <a target="_blank" rel="noreferrer" href="https://edu-cards.com">
            <img className="related" src={edu} alt="EduCards Moldova" />
          </a>
          <span className="top-related">
            <img src={logo} alt="Agenție de servicii de publicitate Total Gravura" />
            <p>&copy; 2005 - {new Date().getFullYear()}</p>
          </span>
          <a target="_blank" rel="noreferrer" href="http://tgprint.md">
            <img className="related" src={print} alt="TG-Print Moldova" />
          </a>
        </div>
      </div>
    );
  }
}
