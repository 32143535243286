import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { Button, Input, message } from "antd";

const { TextArea } = Input;

var containerStyle = {
  width: "640px",
  margin: "50px auto",
};
var iframeWidth = "640"
export default class Contact extends Component {
  state = {};
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  sendEmail = () => {
    var all_ok = true;
    var id = Date.now()
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("number").value;
    const comments = document.getElementById("comments").value;

    if (name.length < 3) {
      message.warning("Numele nu este valid.");
      all_ok = false;
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      message.warning("Email-ul nu este valid.");
      all_ok = false;
      return;
    }
    if (phone.length < 9) {
      message.warning("Numarul de telefon nu este valid.");
      all_ok = false;
      return;
    }

    if (all_ok) {
      var email_text =
        "<b>Numele:</b> " +
        name +
        "<br/><b>Telefon: </b>" +
        phone +
        "<br/><b>Email: </b>" +
        email +
        "<br/><b>Comentarii: </b>" +
        comments +
        " .";

        if (this.state.fileName) {
          let file = document.getElementById("files").files[0];
          let ref = this.props.storage.ref();
          var uploadTask = ref.child("0FilesFromClients/" + id).put(file);
          uploadTask.on("state_changed", () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              email_text += "<br/><b>File: </b>" + downloadURL + "<br/>";
              this.sendIt(email_text);
            });
          });
        } else {
          this.sendIt(email_text);
        }
        this.props.database
            .ref(id)
            .set({
              numele: name,
              email: email,
              telefon: phone,
              Produs: this.state.selectedOption.title,
              timestamp: id,
            })
    }
  };

  sendIt = (text) => {
    
    this.props.db
      .collection("mail")
      .add({
        to: "marketing@totalgravura.md",
        message: {
          subject: "Cerere TotalGravura.md",
          text: text,
          html: text,
        },
      })
      .then(() => {
        document.getElementById("name").value = " ";
    document.getElementById("email").value = " ";
    document.getElementById("number").value = " ";
    document.getElementById("comments").value = " ";
        message.success(
          "Mulțumim! Un manager de vînzări va lua legatura cu dumneavoastră"
        );
      });
  };

  checkFile = () => {
    let fileName = document.getElementById("files").files[0].name;
    if (fileName) {
      this.setState({ fileName });
    }
  };

  render() {
    if (this.state.mobile) {
      containerStyle = {
        width: "320px",
        margin: "20px auto",
      };
      iframeWidth = "320"
    }
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div style={containerStyle}>
          <h1>Contactează compania Total Gravura</h1>
          <label>Numele:</label>
          <br />
          <Input id="name"></Input>
          <br />
          <label>Număr de contact:</label>
          <br />
          <Input id="number"></Input>
          <br />
          <label>Email:</label>
          <br />
          <Input id="email"></Input>
          <br />
          <label>Cu ce vă putem ajuta?</label>
          <br />
          <TextArea rows={4} id="comments"></TextArea>
          <br />
          {this.state.mobile ? (
            <div style={{ display: "block", height: "60px" }}>
              <Button
                block
                style={{
                  margin: "10px auto",
                }}
                onClick={() => document.getElementById("files").click()}
              >
                Adaugă file...
              </Button>
              {this.state.fileName && (
                <p
                  style={{
                    margin: "auto 0px",
                  }}
                >
                  {this.state.fileName}
                </p>
              )}
            </div>
          ) : (
            <div style={{ display: "flex", height: "60px" }}>
              <Button
                style={{
                  margin: "auto 0px",
                }}
                onClick={() => document.getElementById("files").click()}
              >
                Adaugă file...
              </Button>
              {this.state.fileName && (
                <p
                  style={{
                    margin: "auto 10px",
                  }}
                >
                  {this.state.fileName}
                </p>
              )}
            </div>
          )}
        
          <input
            id="files"
            type="file"
            style={{ visibility: "hidden" }}
            onChange={() => this.checkFile()}
            tag="alege"
          ></input>

          <Button
            block
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() => this.sendEmail()}
          >
            Trimite
          </Button>
          <div style={{ display: "block" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680.465087302879!2d28.87260238813909!3d46.98407751359416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97945ba4cfec3%3A0x90d62378bb2dc7e9!2sTotal%20Gravura!5e0!3m2!1sen!2s!4v1611301477966!5m2!1sen!2s"
              width={iframeWidth}
              height="300"
              frameborder="0"
              title="maps"
              aria-hidden="false"
              tabindex="0"
              style={{ marginTop: "20px" }}
            ></iframe>
            <div style={{ marginLeft: "0px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <a
                    className="footer-link"
                    href="https://goo.gl/maps/p9h4jDhqip6rq1uw6"
                  >
                    str. Cernăuți 20A Chișinău, Moldova
                  </a>
                  <a
                    href="mailto:office@totalgravura.md"
                    className="footer-link"
                  >
                    office@totalgravura.md
                  </a>
                </div>
                <Button
                  style={{ margin: "auto 0px auto auto" }}
                  ghost
                  href="tel:+37322558656"
                  type="primary"
                  size="large"
                >
                  022 55 86 56
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
