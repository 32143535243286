import React, { Component } from "react";
import "./SimilarProducts.css";

export default class SimilarProducts extends Component {
  render() {
    return (
      <div className="blocks-container">
        {this.props.list.map((item) => (
          <div className="home-block" key={item.id}>
            {item.id ? (
              <a href={"/" + item.id}>
                <img
                  alt={item.name}
                  className="dropdown-image"
                  src={item.url}
                />
                <h2 style={{ textAlign: "center" }}>{item.name}</h2>
                <p style={{color:'black'}}>{item.text}</p>
                <p>Află mai multe</p>
              </a>
            ) : (
              <div>
                <img
                  alt={item.name}
                  className="dropdown-image"
                  src={item.url}
                />
                <h2 style={{ textAlign: "center" }}>{item.name}</h2>
                <p>{item.text}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
