import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import PresentCarousel from "../blocks/PresentCarousel/PresentCarousel.js";
import Specs from "../blocks/Specs/Specs";

export default class Tehnica extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <h1 style={{ textAlign: "center", marginTop: "40px" }}>Echipamente pentru producția de materiale publicitare</h1>

        <PresentCarousel
          altList={["Imprimare laser", "Лазерная печать", "Servicii imprimare laser"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2FAccurio-PRESS-C83hc.webp?alt=media&token=e9556443-a4cb-4570-8291-2fcb5575756a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2F1215.webp?alt=media&token=3fe27eeb-37ce-4325-bc01-e4a6d77e93b6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2FPIWE8H0.webp?alt=media&token=ceb77e71-f072-4c24-882e-f406a683accb",
          ]}
          title={"Imprimare laser"}
          text={
            "Presă digitală color destinată mediului print on-demand. Viteză ridicată de printare de 71/60 pagini color/minut. Imagini de calitate deosebită. Claritate color și precizie deosebită la rezolutia de 1200x1200 dpix."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "1200x1200 dpi",
            },
            {
              name: "Format echipament",
              description: "A3",
            },
            {
              name: "Greutate hartie",
              description: "62-350g/mp",
            },
            {
              name: "Proces copiere Printare",
              description: "Laser color",
            },
            {
              name: "Sistema Laser",
              description:
                "Imprimare duplex automată pe o lungime max. de 762 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={["taiere Zund", "резка Zund", "надсечка Zund", "обработка Zund", "технологии Zund", "tehnica Zund", "brand Zund"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FCiMSeptember16News-Zu%CC%88nd.webp?alt=media&token=85680507-67f5-4034-b0ba-3a41c5244ebb",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FRM_L_schr__g_Acrylplatte_01.588a07b395049.webp?alt=media&token=74b46cfe-b4f1-4888-bdb2-4ae1d67107ed",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F1.webp?alt=media&token=80069d0f-cd6b-4b45-8315-047ed335c725",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F2.webp?alt=media&token=baf72269-bf89-4956-a288-014c4ef8d8e4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F3.webp?alt=media&token=723d313c-2111-442a-ad47-b6988b132080",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2Fpiele5-_-1545912253.webp?alt=media&token=f374fc8b-e739-48b5-b37e-67ea80d44909",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FP2270004web-845x684.webp?alt=media&token=e7b281e0-0c60-4329-a8c4-d0b0e3a83563",
          ]}
          title={"Zund"}
          text={
            " Caracteristic echipametelor elvetiene Zund pastreaza aceleasi linii cu versatilitate, fiabilitate si rapiditate in lucru oferind posibilitatea optimizarii la maxim a fluxului de lucru. Materialele ce pot fi taiate pot avea grosimi de pâna la 50mm, iar posibilitatile de alegere a modulelor, dimensiunilor mesei de lucru si a optiunilor de manipulare a materialului sunt extrem de variate. Adaptabilitatea neintrecuta la diverse operatiuni este data de flexibilitatea conceptului modular de scule si capete de lucru cu o gama larga de materiale."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Dimensiunile suprafeței de tăiere",
              description: "1600 x 1800 mm",
            },
            {
              name: "Materiale",
              description:
                "Alumini, Vinil, Cauciuc, Lemn, Carton, Plastic, Piele, Hartie, Burete, Textil, Compozit, Dibond",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Printer de format mare - UV",
            "Услуги широкофрматной печати",
            "Imprimarea bannerelor",
            "Печать баннеров",
            "UV Imprimarea",
            "УФ печать",
            "UV печать",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2FUJV55-320-v2.webp?alt=media&token=fedbf087-357d-45e5-bc64-c85e5ed52929",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2FBANNER_BLOCKOUT_9-_-1537368555.webp?alt=media&token=b59aa471-f55c-4922-958b-2da01b1159ad ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbacklite_12-_-1539001020.webp?alt=media&token=f2b3577f-9da1-4a32-91b1-e1e38dae49ab",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbacklite_9-_-1539001018.webp?alt=media&token=684127a1-dc52-46ec-b902-4b95264ed849",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbanner_3-_-1538741982.webp?alt=media&token=6b7c17f9-68ba-4b97-a109-2b60891da606",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbanner_6-_-1538741983.webp?alt=media&token=f2902444-c466-4e38-a3ae-0489b0fd0368",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Ftextil4-_-1551367944.webp?alt=media&token=22713d36-aaa9-4366-bbf4-863a27d720df",
          ]}
          title={"Printer de format mare - UV"}
          subtitle={"LĂŢIME IMPRIMABILĂ: 3,20 M"}
          text={
            "Imprimarea de format mare – presupune imprimarea pe materialul cu o lățime de pîna la 3,2 metre, lungimea materialului imprimat fiind determinată doar de lungimea valului, și se folosește de cele mai multe ori de obicei pentru publicitatea exterioară. Imprimarea de format mare se aplică pe: plafoane, steaguri, plase de construcție, tablouri luminoase, materiale pentru decorațiuni interioare și exterioare, grafică pe podea, bannere de stradă, și etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "300, 450, 600, 900, 1,200 dpi",
            },
            {
              name: "Zona maximă de imprimare",
              description: "3200 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Mimaki CJV300",
            "Imprimare de înaltă calitate",
            "Высококачественная печать",
            "Высокое разрешение",
            "Rezoluție înaltă",
            "Tipărirea de materiale publicitare",
            "Печать рекламных материалов",
            "Servicii de imprimare",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2F1.webp?alt=media&token=490253df-11b0-473d-ad2f-f630963ce856 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fautocolant_decupare_3-_-1539355905.webp?alt=media&token=c9fb0a20-9f6d-48db-95e9-7ac65bc1f391",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fautocolant_decupare_5-_-1539355906.webp?alt=media&token=1994d1ad-a2c3-4951-8ded-09c2479a4424",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fsticker.webp?alt=media&token=aa6868b8-e1f4-476b-afa9-e2f31091f101 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_1-_-1547732387.webp?alt=media&token=453fd690-3b51-4337-82b5-574e4660e468",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_13-_-1547732394.webp?alt=media&token=8d13f1ed-7670-4a5a-995b-4cff297dc582",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_5-_-1547732388.webp?alt=media&token=334b7b88-1327-4ad7-a83b-e90d99150c57 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_9-_-1547732390.webp?alt=media&token=0e68ad19-8a48-47e1-ab6e-a130c8ea68d3",
          ]}
          title={"Mimaki CJV300"}
          text={
            "Modelul dat atinge cele mai mari viteze de imprimare din clasa sa, datorită tehnologiilor inovatoare care permit o calitate uimitoare. Imprimarea rapidă cu viteze de până la 105,9 m2/h, rezoluția de imprimare de înaltă calitate (de până la 1.440 dpi), cu o lățime de imprimare – 1.610 mm. Printul digital se executa pe o gama larga de suporturi media, aplicatiile cel mai des intalnite, pentru printurile digitale."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție de imprimare",
              description: "până la 1.440 dpi",
            },
            {
              name: "Sistem cerneală",
              description: "CMYK Lc, Lm,Lk,Or (SS21) și CMYK (BS3/BS4)",
            },
            {
              name: "Lățime de printare",
              description: "până la 1.361 mm /1.610 mm",
            },
            {
              name: "Viteză de printare",
              description: "până la 105,9 m2/oră",
            },
            {
              name: "Grosime media",
              description: "până la 1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Printer UV cu sistem rotativ",
            "Ротационный УФ-принтер",
            "Imprimare în rolă",
            "Рулонная печать",
            "Imprimare autoadezivă",
            "Печать самоклеек",
            "Imprimarea pe materiale transparente",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FUJF_3042.webp?alt=media&token=02e2f845-9e5e-4ebe-88eb-05274da9e926",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_1-_-1538993088.webp?alt=media&token=ccc07251-e7e9-40b6-b317-d62ca85a905e",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_10-_-1538993093.webp?alt=media&token=54e6d679-0f4f-4ad1-85a2-6eb892578985",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_11-_-1538993093.webp?alt=media&token=a65ffda5-f6ba-46a6-b1d4-f5c73fd1cad5",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_2-_-1538993089.webp?alt=media&token=21717906-e343-4842-8da2-a67fc73e454d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_3-_-1538993089.webp?alt=media&token=fed420f1-8d32-42d5-8a63-10fc968f3aad",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_5-_-1538993092.webp?alt=media&token=7363d1af-0b28-490c-845c-4af3efe035e4",
          ]}
          title={"Printer UV cu sistem rotativ"}
          text={
            "Printarea UV oferă o soluție unică pentru tiraje mici. Imprimantele oferă versatilitate de neegalat și o reproducere de imprimare de înaltă calitate pe o gamă aproape de necrezut de materiale, produse si substraturi. Modelul dat poate printa imagini pe foarte multe tipuri de materiale cum ar fi: geam, acryl, plăci din PVC, material ceramic, aluminiu, metal, lemn, MDF, hârtie, canvas, piele, tapet, autocolant, plastic, etc. Personalizarea și pe produse promoționale laptop-uri, telefoane mobile, odorizante auto, cadouri, rigle, semne indicatoare, plachete din lemn, trofee, panouri de control pentru industrie, folii autocolante etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "720 × 600 dpi, 1,440 × 1200 dpi",
            },
            {
              name: "Zona maximă de imprimare",
              description: 'Lățimea: 300 мм (11.8") Lungimea: 420 мм (16.5")',
            },
            {
              name: "Grosimea materialului",
              description: 'Max. 50 мм (1.97")',
            },
            {
              name: "Sistema UV",
              description: "LED-UV (bec led)",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Imprimare pe textil prin sublimare",
            "Сублимация",
            "Печать на прозрачных материалах",
            "Imprimarea pe materiale transparente",
            "Imprimare Oracal",
            "Imprimare на оракал",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2F6597dee80f.webp?alt=media&token=2ab57f60-ed1d-4af8-b6a7-23237a4da08c ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_3-_-1539070663.webp?alt=media&token=9ed8f1d5-d3de-46e9-b48c-77105c15c840 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_2-_-1539070662.webp?alt=media&token=e210d0de-020c-4f49-98fe-fcd5362d488f ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_5-_-1539070663.webp?alt=media&token=0f40e1b0-3e51-4e47-9bf9-119685093182 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_6-_-1539070663.webp?alt=media&token=74e0d75c-6807-4293-a26a-2e9f955c332b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_7-_-1539070663.webp?alt=media&token=ebac9340-afa8-454d-89f5-5edac44107f9 ",
          ]}
          title={"Imprimare pe textil prin sublimare"}
          text={
            "Sublimarea este un procedeu de imprimare realizat cu ajutorul unor cerneluri speciale care, prin încălzire, trec din starea solidă direct în stare gazoasă, fără a mai trece prin starea lichidă. Printre domeniile de utilitate ale imprimarii prin sublimare se regăsesc: industria publicitară, industria textilă și a modei, artele grafice, industria de entertainment, decorațiuni de interior și tapiserie. Sublimarea funcționează doar pentru personalizări pentru textile cu minimum 80% polyester și pentru materiale pretratate pentru sublimare."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "300, 450, 600, 900, 1,200 dpi",
            },
            {
              name: "Lățimea maximă a imprimării",
              description: "1940 mm",
            },
            {
              name: "Lățimea maximă materialului",
              description: "1950 mm",
            },
            {
              name: "Grosimea maximă",
              description: "1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Imprimare directă pe textil",
            "Прямая печать на текстиле",
            "Imprimare pernă",
            "Печать подушек",
            "Imprimare țesăturilor",
            "Печать на ткани",
            "Imprimare sintetică",
            "Печать на синтетических тканях",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2F1.webp?alt=media&token=9dcf0899-5a32-465a-9927-8602b3b39d3a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_1-_-1539096787.webp?alt=media&token=4c79b654-7400-4c85-b521-be39153eaead",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_10-_-1539096790.webp?alt=media&token=042bc397-cc14-4d89-b152-810f75c3f5cb",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_2-_-1539096788.webp?alt=media&token=fa7717a0-399d-41ca-b9e9-695c980c4d85",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_4-_-1539096788.webp?alt=media&token=cb466229-54b3-4932-9d21-5209df67529f",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_5-_-1539096788.webp?alt=media&token=097a5ffd-7540-447a-8c09-7fa66536e7a2",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_8-_-1539096789.webp?alt=media&token=8026ffbf-9676-4d4d-81b9-54e8da01d7e6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_9-_-1539096790.webp?alt=media&token=8ef19207-d104-462d-8a5a-93db126e85f0",
          ]}
          title={"Imprimare directă pe textil"}
          text={
            "La imprimarea directa, cerneala este asezata direct pe materialul pretratat. Aplicatiile materialelor textile imprimate digital sunt extrem de numeroase: productia de confectii (cu realizarea de mostre sau de serii de volum mic si mediu), articole sportive, decoratiuni interioare, standuri de expozitie, tapet, cortine, casete luminoase, steaguri, bannere textile de exterior, copertine si jaluzele, bannere textile de interior, perdele, draperii, huse, fete de masa, fasion design si etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "360, 540, 720, 1080, 1440 dpi",
            },
            {
              name: "Lățimea maximă a imprimării",
              description: "1920 mm",
            },
            {
              name: "Grosimea maximă a materialului",
              description: "1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Laminare în rolă",
            "Ламинирование рулонов",
            "Folie de laminare",
            "Пленка для ламинирования",
            "Ламинация",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2F1.webp?alt=media&token=515ce4ae-18d1-4800-9137-17dc3dca85ac",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT2_2-_-1539942052.webp?alt=media&token=593e6958-2716-4add-9929-6b8249fd018f",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT2_3-_-1539942052.webp?alt=media&token=ca3ba6cb-714f-408e-9fe2-c55ff5bc9a14",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT_2-_-1539872349.webp?alt=media&token=b50f6173-a2b2-4a20-ad50-a01941fd56ce",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_laminat_1-_-1539872348.webp?alt=media&token=95cb06f1-238f-4c0e-88e0-2bd9547c8f54",
          ]}
          title={"Laminare în rolă"}
          text={
            "Procesul de laminare este un process de aderare a două sau mai multe straturi, pentru protejarea printului. În timpul procesului, pelicula film este trecuta pe o rola incalzita si sub presiune rezonabila se lipeste de print. Laminare în rolă oferă un aspect deosebit printului și o rezistență mărită la acțiuni mecanice, ideal pentru laminarea printurilor publicitare."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Format",
              description: "+ А0",
            },
            {
              name: "Tip",
              description: "Fierbinte",
            },
            {
              name: "Temperatura încălzirii",
              description: "140 °C",
            },
            {
              name: "Lățimea laminării",
              description: "1650 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Îndoirea plasticelor",
            "Гибка пластмасс",
            "Сгибание и резка пластичных материалов",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2Fnai-nai.webp?alt=media&token=8550f126-7c97-4c9f-aecf-b7ec6ad12b2a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2Flea-lea-optimizat.webp?alt=media&token=03e08c32-2317-4aaa-84bb-79a02194724d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2FV1.webp?alt=media&token=b0be3274-5e2c-4030-992f-a6168b6d77ea",
          ]}
          title={"Îndoirea plasticelor"}
          text={
            "Îndoirea plasticelor de diferite dimensiuni şi de diverse grosimi, instrumentul, care încălzeşte în mod uniform plăcile acrilice sau orice alt material termoplastic. Aparatul este destinat pentru a realiza rapid îndoituri perfect drepte în producţia de semne publicitare cu susţinere proprie şi alte structuri de expunere pe bază de plastic."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Tip",
              description: "Formare termică",
            },
            {
              name: "Lungimea aplicată",
              description: "1250 mm",
            },
            {
              name: "Grosimea aplicată",
              description: "3-10 mm",
            },
            {
              name: "Varietatea temperaturilor de lucru",
              description: "0-600",
            },
          ]}
        />

        <PresentCarousel
          altList={["Klieverik", "Sublimare termica", "Термическая сублимация", "Сублимация изображений"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2F1.webp?alt=media&token=532a9825-f7ea-4233-82fa-c1c2f329cecc",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fb896048c5de60ba0_org.webp?alt=media&token=049a77a6-d9db-4870-b928-dbbe7cf91e79",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fmaxresdefault.webp?alt=media&token=ce279775-1cfa-42fb-833d-e752972d4775",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fnnainai.webp?alt=media&token=0217ce23-a473-49fb-819b-6cde33bdb2cc",
          ]}
          title={"Klieverik"}
          text={
            "Tehnologia sublimarii termice presupune transferul unei imagini (tiparita in policromie) de pe o suprafata imprimata digital ( hartie speciala imprimata cu cerneala de sublimare ) si transferul acestei imagini pe un suport textil din poliester folosind presiunea si temperaturi adecvate procesului."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Lățimea materialului",
              description: "1650 mm, 2100 mm, 3200 mm",
            },
            {
              name: "Viteza",
              description: "0,1 – 2 m/min",
            },
            {
              name: "Temperatura maximă",
              description: "220 C",
            },
          ]}
        />

        <PresentCarousel
          altList={["CNC Router", "Taierea si gravarea materialilor", "Резка и гравировка материалов", "ЧПУ фрезерно гравировальный станок"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2F1.webp?alt=media&token=a7aec2ca-3fbb-4ec6-b8d1-0c1dc1ef2376 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2FF.webp?alt=media&token=ee5ff277-021f-4b25-ab87-f2185a7ccb75 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2Fc9bd5fd47213b75f6a8bf96bd3d9a99f.webp?alt=media&token=acac7254-1692-4b1e-ac19-b48dc1d27272 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2Fcompozit_1-_-1545916901.webp?alt=media&token=a46588fd-ac76-4f33-ba00-bdd0ac6c242a ",
          ]}
          title={"CNC Router"}
          subtitle={"Decupare și gravare"}
          text={
            "Routerul CNC se utilizeaza pentru taierea si gravarea materialilor de format mare, pentru trasarea materialelor, pentru decuparea de litere sau alte obiecte volumetrice. Materialele care se pot debita sunt: plexiglas, PVC, plastic ABS, forex, lemn, MDF, placaj, aluminiu compozit, alama, inox si alte tipuri de metale, dibond, etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Zona de lucru X, Y, Z",
              description: "1300 * 2500 * 200 mm",
            },
            {
              name: "Viteza rotației axei",
              description: "600-18000RPM",
            },
          ]}
        />

        <PresentCarousel
          altList={["Taierea si gravarea materialilor de format mare", "Резка и гравировка широкоформатных материалов"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F12%2F1.webp?alt=media&token=a3550090-53ac-4a6f-8f75-bc22cda655a9",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F12%2F6-Easy-Steps-to-Get-the-Best-Finish-When-Cutting-Acrylic.webp?alt=media&token=f13c91a5-bb23-49d0-a62c-56f472241867",
          ]}
          title={"KINGSIGN"}
          subtitle={"Gravare și frezare"}
          text={
            "Routerul CNC se utilizeaza pentru taierea si gravarea materialilor de format mare, pentru trasarea materialelor, pentru decuparea de litere sau alte obiecte volumetrice. Materialele care se pot debita sunt: plexiglas, PVC, plastic ABS, forex, lemn, MDF, placaj, aluminiu compozit, alama, inox si alte tipuri de metale, dibond, alucubond etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Zona de lucru X, Y, Z",
              description: "300 * 450* 15 mm",
            },
            {
              name: "Viteza rotației axei",
              description: "600-18000RPM",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Decupare și gravare laser",
            "Лазерная резка и гравировка",
            "Tăiere şi de imprimare prin gravură laser",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2F1.webp?alt=media&token=cab4f1bd-3b34-4eb4-9483-8744dae4d4fe",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2Flc2.webp?alt=media&token=a055bcdb-6eff-4222-918c-d41ae06f154a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2Facrylic.webp?alt=media&token=c896b4b2-1e35-42b2-8578-bd59ab3cfd16",
          ]}
          title={"Decupare și gravare laser"}
          text={
            "Procedurile de tăiere şi de imprimare prin gravură laser pot fi deosebit de complexe şi delicate, oferind durabilitate în timp şi acurateţe lucrărilor. Se pot grava/tăia diverse materiale: hârtie/carton, plastic (plexi, acryl), lemn, sticlă, ceramică, metal tratat, aluminiu, marmură şi granit, piele, material textil, plută, burete etc.Tehnologia laser se foloseşte pentru personalizarea prin gravură a obiectelor promoţionale sau a elementelor de signalistică, precum şi pentru tăierea şi decuparea cu precizie a diferitelor materiale."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Zona de lucru",
              description: "1300X900 mm si 600x900 mm",
            },
            {
              name: "Tip laser",
              description: "CO2 laser",
            },
            {
              name: "Viteza gravare",
              description: "0-54000 mm/min",
            },
            {
              name: "Temperatura de lucru",
              description: "0-45 °C",
            },
            {
              name: "Putere laser",
              description: "130 W",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Taierea laser a metalului",
            "Лазерная резка металлов",
            "Taierea de calitate",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F14.webp?alt=media&token=6195a929-0ab6-41ee-be71-6dab37b3d455",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F14%2Fwhat-it-looks-like-when-a-laser-is-cutting-through-3erp-200716.webp?alt=media&token=53452099-ae80-4fc5-87c6-97461be31577",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F14%2FMy-Metal-Business-Card-Photography-Stainless-Steel-Card.webp?alt=media&token=565aacf4-8846-4e9d-a219-c525963fc7b8",
          ]}
          title={"Taierea laser a metalului"}
          text={
            "Datorita tehnologiei laser, puteti scoate cel mai bun timp de gravat/taiat materiale, la o calitate superioara si curata. laserul lucreaza curat si silentios iar materialul ce a fost taiat, are muchiile netede, fiecare detaliu avand un finisaj perfect! Procedurile de tăiere laser pot fi deosebit de complexe şi delicate, oferind durabilitate în timp şi acurateţe lucrărilor."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Zona de lucru",
              description: "1300*900 mm",
            },
            {
              name: "Putere laser",
              description: "1500",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Gravare laser",
            "Лазерная гравировка",
            "Marcarea coduri de bare, numere de serie, date, nume de produse",
            "Маркировка штрих-кодов, Маркировка QR-кодов, нанесение серийных номеров, нанесение дат, наименований продуктов",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2F1.webp?alt=media&token=a605c12b-0bfb-454d-b7a1-f69976ca0dd4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2FMetal%20Marking%20QR%20Code%20Tags.webp?alt=media&token=5836c47f-a5a2-4066-adad-4a0afc59a924",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2Flelele-opt.webp?alt=media&token=969382cf-1961-4dc7-8dbc-871d487667a6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2Fmaxresdefault-2.webp?alt=media&token=adc61678-97ec-4fa5-abfc-f41d57a2b824",
          ]}
          title={"Gravare laser"}
          text={
            "Masina laser de marcare cu fibră optica este utilizată pentru marcarea diferitelor obiecte, cum ar fi coduri de bare, numere de serie, date, nume de produse, diversa grafică și diferite modele pe materiale metalice, plastice, și altele. Se pot personaliza diferite obiecte ca: tastatura, ustensile de bucătărie, accesorii auto, butoane din plastic, sticla, echipamente medicale, ceasuri, obiecte metalice etc. Caracteristici principale sunt - viteza mare și eficiență ridicată, de 2-3 ori mai rapidă decât cea tradițională."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Laser wavelength",
              description: "1060mm",
            },
            {
              name: "Marking scale",
              description: "110mm x110mm",
            },
            {
              name: "Minimum font",
              description: "0.15mm",
            },
            {
              name: "Engraving liner speed",
              description: "7000mm/s",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Gravare laser 3D",
            "3D-гравировка",
            "Mașina de marcare laser cu fibră optică",
            "Волоконно-лазерная маркировочная машина",
            "Gravarea pe obiecte volumetrice",
            "Гравировка на объемных объектах",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F16.webp?alt=media&token=e3026133-5bf0-4b26-91e0-0715d065903d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F0069981c3c4f00facac5ee38f629e0d9.webp?alt=media&token=3bd7f7a3-f4fe-4db2-8331-b50f254f8a59",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F131323123.webp?alt=media&token=a0fc2b92-5a55-4007-819b-91b2b1d70ca6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F972d4b7cbe3fd0d4523351ee8eb17f19.webp?alt=media&token=07b3ec9d-3969-4588-9c97-31bd9750d129",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2Fa225fa1a937c0c2e3a0da016a8da4ab9.webp?alt=media&token=41192613-f7cc-4798-82a6-1f120ae9c157",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F0e260c89af47ef7ee3cdbfa9bd639f46.webp?alt=media&token=e7bc85ee-074b-4f5a-9708-b3dc5df28fa1",
          ]}
          title={"Gravare laser 3D"}
          text={
            "Mașina de marcare laser cu fibră optică. Avantajul e ca putem face gravarea nu numai pe obiecte plane dar și pe obiecte volumetrice. Laserul convine pentru personalizarea obiectelor promotionale din materiale solide sau flexibile, cu o precizie exacta."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Grosimea laser",
              description: "<0.3mm",
            },
            {
              name: "Precizie",
              description: "±0.003mm",
            },
            {
              name: "Viteza",
              description: "8000mm/s",
            },
            {
              name: "Dimensiunea fontului",
              description: "0.5-110mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Mașina de marcare laser UV",
            "Ультрафиолетовая лазерная маркировочная машина",
            "Gravare UV",
            "УФ гравировка",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F1.webp?alt=media&token=09fa7913-43dd-4cb9-ad93-14721c4ea065",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F0d898dc43e5681dc296905c672838ae3.webp?alt=media&token=a88547df-614a-4c26-a46a-d4f2f8a336f7",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F92a10bb1fb44116aee409c88992dafdd.webp?alt=media&token=c01fffc5-c4c3-4003-a158-2132475525d9",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F070c5a7d02d2e31523e5f4f9d7fe0e7a.webp?alt=media&token=6a15bf3a-cca9-488a-84aa-13cba7088b6b",
          ]}
          text={
            "Mașina de marcare laser UV este prima mașină de marcare cu laser UV cu trei tehnologii de control al axei,poate marca pe o suprafață robustă, circulară și în alte forme speciale."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Grosimea laser",
              description: "<0.3mm",
            },
            {
              name: "Precizie",
              description: "±0.003mm",
            },
            {
              name: "Viteza",
              description: "8000mm/s",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Gravare pe acril, PVC, foaie de cauciuc, plastic, cristal, sticlă, lemn, bambus, oxhorn, figulină, marmură, piele, pânză, tablă dublă",
            "Marcare laser UV",
            "УФ маркировка",
            "УФ печать",
            "Гравировка на всех типах материалов - акрил, ПВХ, листовая резина, пластик, хрусталь, стекло, дерево, бамбук, фигулин, мрамор, кожа, ткань",
            "Gravare pe toate tipurile de materiale",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F18.webp?alt=media&token=4b104c5e-ec62-4896-94ad-d6ffb01ecce4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F8303cb38610c4dbbbc7bfdb4b2978dd2.webp?alt=media&token=76ce8e30-62d1-4bf5-9990-d4338750f28a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F131313.webp?alt=media&token=4ffdafbe-b670-4740-aef4-5f2c7e34b800",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2Fmaxresdefault-2.webp?alt=media&token=eaddfcce-917f-4d95-9b8d-c023f368a62b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2Fwholesale-new-design-best-price-free-logo-paper-laser-cut-baby-naming-ceremony-invitation-cards.webp?alt=media&token=2b9495fe-3201-4232-bb19-80ecf0cfe7d8",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F50pcs-wholesale-laser-cut-invitatios-wedding.webp?alt=media&token=6d90c136-e2f7-4a62-bcaf-4a3b5e371cfe",
          ]}
          title={"Marcare laser UV"}
          text={
            "Modelul dat are o eficiență ridicată, cu o zonă mare de marcare și un fascicul de laser cu marcare fină. Prelucrare de mare viteză cu o mare precizie Materiale de aplicare: acril, PVC, foaie de cauciuc, plastic, cristal, sticlă, lemn, bambus, oxhorn, figulină, marmură, piele, pânză, tablă dublă etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Arie de gravare",
              description: "600 x 600 mm",
            },
            {
              name: "Precizie",
              description: "±0.001 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Mașină de lustruit",
            "Полировальная машина",
            "Lustruire obiectelor din material acrilic si plexiglass",
            "Полировка предметов из акрила и оргстекла",
            "Rășină epoxidică",
            "Эпоксидная смола",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F19.webp?alt=media&token=9b63b191-79fa-42a4-b019-42416105ac3f",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2Fc117659faa9322c7acf085381a7aac21.webp?alt=media&token=eb11f9f8-4fb7-47e9-a824-6d6ea4874178",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F1071462a6cc952d437801626b8e3e445.webp?alt=media&token=553bd151-e4c2-4ef4-8976-16a7a4a19f6d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F3b3a5a9a72f6bffbfe79367414cb66de.webp?alt=media&token=38b2dd26-b8a4-458b-be59-cab216df75c8",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F94ede3c5878cf92ecf454772d01e2724.webp?alt=media&token=169214d8-817d-49ef-a9a0-dc3ed32847fc",
          ]}
          title={"Mașină de lustruit"}
          text={
            "Obiectele din material acrilic si plexiglass se zgarie foarte usor si au un aspect neângrijit și uzat. Aceasta situație se regasește frecvent la componentele din material acrilic. O rezolvare simpla si eficienta este lustruirea materialilor, cel mai des se folosește pentru trofee, suvenire și produse diverse din sticlă organică."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Dimensiunile zonei de lucru",
              description: "1000mm(L)×100mm(T) (extensible)",
            },
            {
              name: "Viteza",
              description: "0-1m/min",
            },
            {
              name: "Unghi de lucru",
              description: "0-60 de grade",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Tehnologie de imprimare",
            "Технологии печати",
            "Оборудование для печати",
            "Echipamente de imprimare",
            "Printarea UV pentru tiraje mici",
            "Ультрафиолетовая печать для малых тиражей",
            "Culori strălucitoare",
            "Яркие цвета",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2F1.webp?alt=media&token=98cd92af-a3db-4f62-8230-8ea80ca434d2",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_1-_-1538380839.webp?alt=media&token=938f14c3-7605-47da-ab8f-5b1c964b252b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_10-_-1538380839.webp?alt=media&token=fe6c034e-dd67-41c6-a4ef-29d983d86113",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_7-_-1538380838.webp?alt=media&token=933cc855-bae7-439c-849d-6b5a17a7444c",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fimprimare_PAL_1-_-1538404341.webp?alt=media&token=e2f99492-d906-4b1b-84df-9cc523a5da53",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fplacaj_4-_-1538465928.webp?alt=media&token=379d0a8c-6c25-4a37-b58d-c5a551b69099",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fsticla_silicata_11-_-1538400714.webp?alt=media&token=f48aea82-344d-480c-b304-4e3a69a3e955",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fsticla_silicata_4-_-1538400711.webp?alt=media&token=3d644a0b-99e6-489b-911f-b2a9d7145177",
          ]}
          title={"Printer UV"}
          text={
            "Printarea UV oferă o soluție unică pentru tiraje mici. Imprimantele oferă versatilitate de neegalat și o reproducere de imprimare de înaltă calitate pe o gamă aproape de necrezut de materiale, produse si substraturi. Modelul dat poate printa imagini pe foarte multe tipuri de materiale cum ar fi: geam, acryl, plăci din PVC, material ceramic, aluminiu, metal, lemn, MDF, hârtie, canvas, piele, tapet, autocolant, plastic, etc. Personalizarea și pe produse promoționale laptop-uri, telefoane mobile, odorizante auto, cadouri, rigle, semne indicatoare, plachete din lemn, trofee, panouri de control pentru industrie, folii autocolante etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Rezoluție",
              description: "720 × 600 dpi, 1,440 × 1200 dpi",
            },
            {
              name: "Zona maximă de imprimare",
              description: 'Lățimea: 1300 мм (11.8") Lungimea: 2500 мм (16.5")',
            },
            {
              name: "Grosimea materialului",
              description: 'Max. 50 мм (1.97")',
            },
            {
              name: "Sistema UV",
              description: "LED-UV (bec led)",
            },
          ]}
        />

        <Footer />
      </div>
    );
  }
}
