import React, { Component } from "react";
import "../ImageText/ImageText.css";

export default class VideoText extends Component {

  state = {

  }
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    let maxWidth = window.innerWidth >= 1500
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth});
    }
    if(!maxWidth){
    this.setState({width: window.innerWidth})

    }else{
        this.setState({width:1500})
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {

    if(this.state.mobile){
        return (
          <div className="imageText-container">
            {this.props.description && <div id="description"></div>}
            <div className="text-container">
              <p className="text">
                <h2>{this.props.title}</h2>
                {this.props.text}
              </p>
            </div>
            <div className="image-container" style={{textAlign:'center'}}>
            <iframe width={this.state.width * 0.9} height={this.state.width*0.9 / 1.77} src={this.props.video} title={this.props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        );
      }else{

    if (this.props.reverse) {
        return (
            <div className="imageText-container">
              {this.props.description && <div id="description"></div>}
              <div className="text-container">
                <p className="text">
                  <h2>{this.props.title}</h2>
                  {this.props.text}
                </p>
              </div>
              <div className="image-container">
              <iframe width={this.state.width * 0.5} height={this.state.width*0.5 / 1.77} src={this.props.video} title={this.props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  
              </div>
            </div>);
      }else{
        return(
            <div className="imageText-container">
            {this.props.description && <div id="description"></div>}
            <div className="image-container">
            <iframe width={this.state.width * 0.5} height={this.state.width*0.5 / 1.77} src={this.props.video} title={this.props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="text-container-right">
              <p className="text">
                <h2>{this.props.title}</h2>
                {this.props.text}
              </p>
            </div>
          </div>);
      }
      
    } 

  }
}
