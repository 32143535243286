import React, { Component } from "react";
import "./BackgroundText.css";
export default class BackgroundText extends Component {
  render() {
    var background = {
      height: "600px",
      backgroundImage: "url(" + this.props.img + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      margin: "20px 0px",
      justifyContent: "space-around",
    };
    if (this.props.reverse) {
      return (
        <div style={background}>
          <div className="text-white-left">
            <h2 style={{ textAlign: "center" }}>{this.props.title}</h2>
            <p>{this.props.text}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div style={background}>
          <div className="text-white-right">
            <h2 style={{ textAlign: "center" }}>{this.props.title}</h2>
            <p>{this.props.text}</p>
          </div>
        </div>
      );
    }
  }
}
