import { Button } from "antd";
import React, { Component } from "react";
import "../ImageList/ImageList.css";
export default class FAQ extends Component {
  render() {
    return (
      <div style={{margin: '40px 0px'}}>
        {this.props.list.map((item, index) => (
          <div key={index} className='image-list-item'>
            <div className="image-list-content">
              <h2>{item.title}</h2>
              <p>{item.text}</p>
              {item.url && <Button type="link" href={item.url}>Află mai multe</Button>}
              {this.props.blog && <Button type='text' href={"/blog/"+item.url}>Află mai multe</Button>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
