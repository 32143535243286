import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import '../blocks/Specs/Specs.css'

const rechizite = [
  {
    name:'Adresa juridică',
    description:'MD-2060, mun. Chisinau, str. Cuza Voda nr 6 ap 31'
  },
  {
    name:'Adresa oficiului',
    description:'mun. Chisinau, str.Cernauti 20A'
  },
  {
    name:'Cod fiscal (IDNO)',
    description:'1004600069150'
  },
  {
    name:'TVA',
    description:'0305217'
  },
  {
    name:'Cont bancar',
    description:'MD03PR002251173103001498'
  },
  {
    name:'Swift',
    description:'PRCBMD22'
  },
  {
    name:'Numele bancii',
    description:'B.C.’Procredit Bank’ S.A.'
  },
]

export default class Rechizite extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <h2 className="specs-heading">Rechizite</h2>
        <h1 style={{margin:'20px 0px', textAlign:'center'}}><b>SC "TOTAL GRAVURA" SRL </b></h1>
        <table>
          <tbody>
            {rechizite.map((item) => (
              <tr valign="top" key={item.name}>
                <td className='spec-name'>
                  <b>{item.name}</b>
                </td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <Footer />
      </div>
    );
  }
}
