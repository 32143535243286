import { Col, Row } from "antd";
import React, { Component } from "react";
import "./FourBlocks.css";

export default class FourBlocks extends Component {

  state = {
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }


  



  render() {
    const data = this.props.data;

    if(this.state.mobile){
      return (
        <div>
          <img src={this.props.background} alt="Total Gravura - producție publicitară, imprimare de bannere, gravură - изготовление рекламы, печать баннеров, гравировка" style={{width:'100%'}}/>

          <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[0].alt}
                src={data[0].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[0].title}</h2>
                <p>{data[0].text}</p>
              </div>
            </div>


            <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[1].alt}
                src={data[1].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[1].title}</h2>
                <p>{data[1].text}</p>
              </div>
            </div>

            <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[2].alt}
                src={data[2].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[2].title}</h2>
                <p>{data[2].text}</p>
              </div>
            </div>

            <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[3].alt}
                src={data[3].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[3].title}</h2>
                <p>{data[3].text}</p>
              </div>
            </div>

        </div>
      )
    }
    return (
      <div
        className="four-block"
        style={{
          backgroundImage: "url(" + this.props.background + ")",
        }}
      >
        <Row>
          <Col span="12" 
          style={{ height: "400px" }}
          >
            <div className="left-block">
              <div className="mini-block-text">
                <h2>{data[0].title}</h2>
                <p>{data[0].text}</p>
              </div>
              <img
                className="mini-block-image"
                alt={data[0].alt}
                src={data[0].img}
              ></img>
            </div>
          </Col>
          <Col span="12">
            <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[1].alt}
                src={data[1].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[1].title}</h2>
                <p>{data[1].text}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <div className="left-block">
              <div className="mini-block-text">
                <h2>{data[2].title}</h2>
                <p>{data[2].text}</p>
              </div>
              <img
                className="mini-block-image"
                alt={data[2].alt}
                src={data[2].img}
              ></img>
            </div>
          </Col>
          <Col span="12">
            <div className="right-block">
              <img
                className="mini-block-image"
                alt={data[3].alt}
                src={data[3].img}
              ></img>
              <div className="mini-block-text">
                <h2>{data[3].title}</h2>
                <p>{data[3].text}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
