import React, { Component } from "react";
import "./Companies.css";

export default class Companies extends Component {
  state = {
    letterData: [],
    companies: [],
  };
  componentDidMount = () => {
    var companies = [];
    this.props.db
      .collection("companies")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let temp = {
            id: doc.id,
            data: doc.data().data,
          };
          if (doc.id !== "0") {
            companies.push(temp);
            this.setState({ companies: companies });
          } else {
            this.setState({ img: doc.data().data });
          }
        });
      })
      .then(() => {
        this.setState({
          letterData: this.state.companies[0].data,
          selectedLetter: this.state.companies[0].id,
        });
      });
  };

  getButtonStyle = (item) => {
    if (this.state.selectedLetter === item.id) {
      return {
        margin: "4px 6px",
        backgroundColor: "#831b29",
        color: "#fff",
       

      };
    } else {
      return {
        margin: "4px 6px",
        backgroundColor: "#fafafa",
        color: "#000",

      };
    }
  };

  render() {
    return (
      <div>
        <div style={{}}>
          <div
            style={{
              backgroundImage: "url(" + this.state.img + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "100%",
            }}
            className="company-img"
          >
            {this.state.letterData.map((company, index) => (
              <span key={company}>
                {index !== 0 && (
                  <p className="company-name" style={{ margin: "2px 4px" }}>
                    &#8226;
                  </p>
                )}
                <p className="company-name" style={{ margin: "2px 4px" }}>
                  {company}
                </p>{" "}
              </span>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "0px 10px",
            marginTop: "10px",
          }}
        >
          {this.state.companies.map((item) => (
            <button
            className='letter-btn'
              key={item.id}
              style={this.getButtonStyle(item)}
              onMouseOver={() =>
                this.setState({
                  letterData: item.data,
                  selectedLetter: item.id,
                })
              }
            >
              {item.id}
            </button>
          ))}
        </div>
      </div>
    );
  }
}
