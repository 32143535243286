import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";

export default class Parteneri extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />

        <Footer />
      </div>
    );
  }
}
