import React, { Component } from "react";
import "./Specs.css";

export default class Specs extends Component {
  render() {
    return (
      <div>
        {this.props.material && <div id='description'></div>}
        <h1 className="specs-heading">Specificații tehnice{this.props.tech && " Tehnice"}</h1>
        <table>
          <tbody>
            {this.props.list.map((item) => (
              <tr valign="top" key={item.name}>
                <td className='spec-name'>
                  <b>{item.name}</b>
                </td>
                <td>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  }
}
