import { Button } from "antd";
import React, { Component } from "react";
import "./ImageList.css";
export default class ImageList extends Component {

  state = {

  }
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    if(this.state.mobile){
      return(
        <div style={{margin: '40px 0px'}}>
        {this.props.list.map((item, index) => (
          <div key={index} className='image-list-item'>
                <div className="image-list-content"><h2>{item.title}</h2></div>
            <img src={item.img} alt={item.alt} className="image-list-img" />
            <div className="image-list-content">
              <p>{item.text}</p>
              {this.props.blog && <Button type='text' href={"/blog/"+item.url}>Află mai multe</Button>}
            </div>
          </div>
        ))}
      </div>
      )
    }
    return (
      <div style={{margin: '40px 0px'}}>
        {this.props.list.map((item, index) => (
          <div key={index} className='image-list-item'>
            <img src={item.img} alt={item.alt} className="image-list-img" />
            <div className="image-list-content">
              <h2>{item.title}</h2>
              <p>{item.text}</p>
              {this.props.blog && <Button type='text' href={"/blog/"+item.url}>Află mai multe</Button>}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
