import React, { Component } from "react";
import "./ImageText.css";

export default class ImageText extends Component {

  state = {

  }
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {

    if(this.state.mobile){
        return (
          <div className="imageText-container">
            {this.props.description && <div id="description"></div>}
            <div className="text-container"> <h2>{this.props.title}</h2></div>
            <div className="image-container">
              <img
                className="image"
                src={this.props.img}
                alt={this.props.alt}
              ></img>
            </div>
            <div className="text-container">
              <p className="text">
                {this.props.text}
              </p>
            </div>
          </div>
        );
      }else{

    if (this.props.reverse) {
        return (
          <div className="imageText-container">
          {this.props.description && <div id="description"></div>}
          <div className="text-container">
            <p className="text">
              <h2>{this.props.title}</h2>
              {this.props.text}
            </p>
          </div>
          <div className="image-container">
            <img
              className="image"
              src={this.props.img}
              alt={this.props.alt}
            ></img>
          </div>
        </div>);
      }else{
        return(
          <div className="imageText-container">
          {this.props.description && <div id="description"></div>}
          <div className="image-container">
            <img
              className="image"
              src={this.props.img}
              alt={this.props.alt}
            ></img>
          </div>
          <div className="text-container-right">
            <p className="text">
              <h2>{this.props.title}</h2>
              {this.props.text}
            </p>
          </div>
        </div>
        );
      }
      
    } 

  }
}
