import React, { Component } from "react";
import "./CircleList.css";
export default class CircleList extends Component {
  render() {
    return (
      <div className='circles-container' >
        {this.props.list.map((item, index) => (
          <div key={index} className="circle-list">
            <img src={item.img} alt={item.alt} />
            <b>{item.title}</b>
            <br />
            <br />

            {item.list.map((li, index) => (
              <li key={index}>{li}</li>
            ))}

            <div className="bold-normal">
              <b>{item.bold} </b>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
