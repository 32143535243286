import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";

export default class Avantaje extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div style={{ margin: "20px auto", maxWidth: "600px" }}>
          <h1 style={{ textAlign: "center" }}>Avantajele Total Gravura</h1>
          <ul
            style={{ margin: "30px", fontWeight: "light", fontSize: "1.1em" }}
          >
            <li> Suntem o companie cu experiență in domeniul publicitar de 16 ani{" "}
            </li>
            <li> Toate comenzile se execută în 1-2 zile </li>
            <li> Toate echipamentele sunt de ultimă generație </li>
            <li> Avem o echipă de specialiști formată din 25 de persoane </li>
            <li>
              {" "}
              Suntem foarte flexibili și mereu căutam cea mai bună soluție
              pentru problema dvs.{" "}
            </li>
            <li>
              {" "}
              Cea mai dotată tehnic agenție de publicitate din R.Moldova{" "}
            </li>
            <li> Abordare individuală a fiecărui client </li>
            <li>
              {" "}
              Un sistem flexibil de plată și stimulare pentru clienți permanenți{" "}
            </li>
            <li> Peste 12.000 clienți mulțumiți </li>
            <li> Parteneri a camerei de comerț și industrie din R.Moldova </li>
            <li> Calitate superioară la orice produs sau serviciu executat </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}
