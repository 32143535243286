
import React, { Component } from "react";
import {Image} from 'antd'
import "./Gallery.css";
export default class Gallery extends Component {
  render() {
    return (
      <div style={{margin: '40px 0px', display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
        {this.props.list.map((item, index) => (
          <Image alt={this.props.altList[index]} src={item} key={index+1} style={{margin:'20px'}} className='gallery-image'/>
        ))}
      </div>
    );
  }
}
