import React, { Component } from "react";
import Header from "./Header";
import ImageText from "./blocks/ImageText/ImageText";
import Footer from "./Footer";
import BackgroundText from "./blocks/BackgroundText/BackgroundText";
import SimilarProducts from "./blocks/SimilarProducts/SimilarProducts";
import Specs from "./blocks/Specs/Specs";
import MainBlock from "./blocks/MainBlock/MainBlock";
import ImageContact from "./blocks/ImageContact/ImageContact";
import FourBlocks from "./blocks/FourBlocks/FourBlocks";
import ProductPresent from "./blocks/ProductPresent/ProductPresent";
import PresentCarousel from "./blocks/PresentCarousel/PresentCarousel";
import Top from "./blocks/Top/Top";
import ImageList from "./blocks/ImageList/ImageList";
import VideoText from "./blocks/VideoText/VideoText";
import CircleList from "./blocks/CircleList/CircleList";
import Gallery from "./blocks/Gallery/Gallery";
import FAQ from "./blocks/FAQ/FAQ";

export default class Product extends Component {
  state = {
    blocks: [],
    main: null,
  };

  componentDidMount = () => {

    if(!this.props.blog){
      const docRef = this.props.db
      .collection("blocks")
      .doc(this.props.product.id);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          var sortedArray = []
          if(Array.isArray(doc.data().blocks)){
            sortedArray = doc.data().blocks
          }
          sortedArray.sort((a, b) => (a.priority - b.priority) )
          this.setState({
            blocks: sortedArray,
            main: doc.data().main,
          });
          if(doc.data().main.title){
            document.title ="Total Gravura - " + doc.data().main.title
          }
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    }else{
      var sortedArray = []
      if(Array.isArray(this.props.product.data.blocks)){
        sortedArray = this.props.product.data.blocks
      }
      sortedArray.sort((a, b) => (a.priority - b.priority) )
      this.setState({
        blocks: sortedArray,
      });
    }
    
  };

  render() {
    var main = this.state.main;
    return (
      <div>

        <Header db={this.props.db} categories={this.props.categories} />
        {/* BLOCKS BEGIN */}
        {main && (
          <MainBlock
            title={main.title}
            subtitle={main.subtitle}
            imgList={main.imgList}
            altList={main.altList}
            text={main.text}
            constructor={main.constructor}
            size={main.size}
            db={this.props.db}
            storage={this.props.storage}
            database={this.props.database}
          />
        )}
        <div
        style={{
          maxWidth:"1500px",
          margin:"0px auto"
        }}>
        {this.state.blocks.map((block, index) => (
          <div key={block.id + index}>
            {block.id === "BT1" && (
              <BackgroundText
                text={block.text}
                title={block.title}
                img={block.img}
                reverse={block.reverse}
              />
            )}
            {block.id === "Main" && (
              <MainBlock
              title={block.title}
              subtitle={block.subtitle}
              imgList={block.imgList}
              altList={block.altList}
              text={block.text}
              constructor={block.constructor}
              categoryPage={block.categoryPage}
              size={block.size}
              db={this.props.db}
              storage={this.props.storage}
              database={this.props.database}
            />
            )}
            {block.id === "IT1" && (
              <ImageText
                text={block.text}
                title={block.title}
                img={block.img}
                alt={block.alt}
                reverse={block.reverse}
                description={block.description}
              />
            )}
            {block.id === "G1" && (
              <Gallery
                list={block.list}
                altList={block.altList}
              />
            )}
            {block.id === "IC1" && (
              <ImageContact
                text={block.text}
                title={block.title}
                subtitle={block.subtitle}
                img={block.img}
                alt={block.alt}
                db={this.props.db}
                storage={this.props.storage}
                database={this.props.database}
                contact={block.contact}
              />
            )}
            {block.id === "S1" && <Specs list={block.data} />}
            {block.id === "FB1" && (
              <FourBlocks data={block.data} background={block.background} />
            )}
            {block.id === "SP1" && <SimilarProducts list={block.list} />}
            {block.id === "I1" && (
              <div
                style={{
                  backgroundImage: "url(" + block.img + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  height: "100vh",
                  maxWidth: "100vw !important",
                  margin: "auto",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              ></div>
            )}
            {block.id === "PP1" && (
              <ProductPresent
                title={block.title}
                text={block.text}
                link={block.link}
                img={block.img}
                alt={block.alt}
                reverse={block.reverse}
              />
            )}
            {block.id === "PC1" && (
              <PresentCarousel
                title={block.title}
                text={block.text}
                link={block.link}
                list={block.list}
                altList={block.altList}
                subtitle={block.subtitle}
              />
            )}
            {block.id === "V1" && (<VideoText 
                video={block.video}
                title={block.title}
                text={block.text}
                reverse={block.reverse}
           ></VideoText>)}
            {block.id === "TOP" && <Top />}
            {block.id === "IL1" && <ImageList list={block.list} />}
            {block.id === "FAQ" && <FAQ list={block.list} />}
            {block.id === "CL1" && <CircleList list={block.list} />}
            {block.id === "H1" && (
              <h1 style={{ textAlign: block.align, margin: "40px 0px 0px" }}>
                {block.title}
              </h1>
            )}
          </div>

          
        ))}
        {/* END OF BLOCKS */}
        </div>
        <Footer />
      </div>
    );
  }
}
