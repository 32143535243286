import { Button, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import "./pages.css";

class Materiale extends Component {
  state = {
    materials: [],
  };

  componentDidMount = () => {
    this.setState({materials:this.props.materials})
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    if(this.state.mobile) {
      return (<div>
        <Header db={this.props.db} categories={this.props.categories} />
        <Menu mode="inline" style={{ overflowX: "hidden" }}>
        {this.state.materials.map((cat) => (
                    <SubMenu
                      key={cat.name}
                      title={cat.name}
                      style={{fontWeight:'bold', textAlign:'center'}}
                    >
 <div className="blocks-container" style={{marginBottom:'30px'}}>
                      {cat.data.items.map((item) => (

                          <Menu.Item
                            key={item.name}
                            disabled

                            className="menu-item-sub"
                            style={{ fontWeight: "bold", display:'inline-block', cursor: "default",height:'auto' }}
                          >
                            <div className="home-block" key={item.id}>
                      <a href={"/material/" + item.id}>
                        <img
                          alt={item.name}
                          className="dropdown-image"
                          src={item.images[0]}
                        />
                        <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                          {item.name}
                        </h2>
                      </a>
                    </div>
                          </Menu.Item>

                      ))}</div>
                    </SubMenu>
                  ))}
        </Menu>
<Footer />
      </div>)
    }
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div style={{ display: "flex" }}>
          <div className="menu-materiale">
            <h4 style={{ textAlign: "center" }}>Categorii</h4>
            {this.state.materials.map((item) => (
              <div>
                <Button href={"#" + item.data.id} type="link">
                  {item.name}
                </Button>
              </div>
            ))}
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            {this.state.materials.map((item) => (
              <div>
                <h2
                  id={item.data.id}
                  style={{ textAlign: "center", display: "block" }}
                >
                  {item.name}
                </h2>
                <div className="blocks-container">
                  {item.data.items.map((material) => (
                    <div className="home-block" key={material.id}>
                      <a href={"/material/" + material.id}>
                        <img
                          alt={material.name}
                          className="dropdown-image"
                          src={material.images[0]}
                        />
                        <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                          {material.name}
                        </h2>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Materiale;
