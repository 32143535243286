import { Button } from "antd";
import { Carousel } from "react-bootstrap";
import React, { Component } from "react";
import "./PresentCarousel.scss";

export default class PresentCarousel extends Component {
  render() {
    return (
      <div className="pc-container">
        <div className="pc-content">
          <h1 className="pc-h1">{this.props.title}</h1>
          <h2 className="pc-h2">{this.props.subtitle}</h2>
          <h3 className="pc-h3">{this.props.text}</h3>
          {this.props.link &&
          <Button href={"/" + this.props.link} type="primary" size="large" alt="Află mai multe despre serviciile de publicitate ale Total Gravura">
            Află mai multe
          </Button>}
        </div>
        <div className="pc-carousel use-boots">
          <Carousel style={{color:"red"}} interval={null}>
            {this.props.list.map((image, index) => (
              <Carousel.Item key={index}>
                <img src={image} alt={this.props.altList[index]} className="pc-image" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
