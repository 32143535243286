import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";

// Import of components here:
import App from "./App";
import Product from "./components/Product";
import Contact from "./components/pages/Contact";
import Despre from "./components/pages/Despre";
import Blog from "./components/pages/Blog";
import Parteneri from "./components/pages/Parteneri";
import Rechizite from "./components/pages/Rechizite";
import Tehnica from "./components/pages/Tehnica";
import Materiale from "./components/pages/Materiale";
import Avantaje from "./components/pages/Avantaje";
import Material from "./components/Material";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBtTskynE88UP0IAqkwsWF9DbZvAVrLg_M",
  authDomain: "total-gravura.firebaseapp.com",
  databaseURL: "https://total-gravura.firebaseio.com",
  projectId: "total-gravura",
  storageBucket: "total-gravura.appspot.com",
  messagingSenderId: "955571010254",
  appId: "1:955571010254:web:33896b1dfdcfe1db7cae9f",
  measurementId: "G-R9V240WG7X",
};

firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();
var storage = firebase.storage();
var database = firebase.database();

var categories = [];
var materials = [];
var materials_list = [];
var categories_data = [];
var options = []
var blogPosts = []

db.collection("categories")
  .get()
  .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      // CONSOLE
      if(doc.data().hidden !== true){
        var url = doc.id.replace(/\s/g, '-')
      var temp = {
        name: doc.id,
        data: doc.data(),
        url: url
      };
      if (doc.id === "Servicii") {
        categories_data.unshift(temp);
      } else {
        categories_data.push(temp);
      }
    }
    });
  })
  .then(() => {
    db.collection("materials")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // CONSOLE

          var temp = {
            name: doc.id,
            data: doc.data(),
          };

          materials.push(temp);
        });
      });
  })
  .then(() => {
    db.collection("materials")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          for (let i = 0; i < doc.data().items.length; i++) {
            materials_list.push(doc.data().items[i]);
          }
        });
      });
  })
  .then(() => {
    db.collection("blog")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var temp = {
            id: doc.id,
            data: doc.data(),
          }
          blogPosts.push(temp)
        });
      });
  })
  .then(() => {
    db.collection("categories")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if(doc.data().hidden !== true){
            for (let i = 0; i < doc.data().subs.length; i++) {
            for (let j = 0; j < doc.data().subs[i].product.length; j++) {
              categories.push(doc.data().subs[i].product[j]);
            }
          }
          }
          
        });
      })
      .then(() => {
        db.collection("blocks")
          .get()
          .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var temp = {}
              if(doc.data().main){
              if(doc.data().main.size === true){
                temp.title = doc.data().main.title 
                if(Array.isArray(doc.data().main.constructor)){
                temp.constructor = doc.data().main.constructor
                }
                options.push(temp)
              }}
            });
          });
      })
      .then(() => {
        ReactDOM.render(
          <Router>
            <Switch>
              <Route exact strict path="/">
                <App db={db} database={database} categories={categories_data} list={categories} storage={storage} options={options} />
              </Route>
              {categories.map((product) => (
                <Route key={product.id} path={"/" + product.id}>
                  <Product
                    db={db}
                    database={database}
                    storage={storage}
                    product={product}
                    categories={categories_data}
                    list={categories}
                  />
                </Route>
              ))}
              {categories_data.map((category) => (
                <Route key={category.name} path={"/" + category.url}>
                   <Product
                    db={db}
                    database={database}
                    storage={storage}
                    product={category}
                    blog={true}
                    categories={categories_data}
                    list={categories}
                  />
                </Route>
              ))}
              {blogPosts.map((post) => (
                <Route key={post.id} path={"/blog/" + post.id}>
                  <Product
                    db={db}
                    database={database}
                    storage={storage}
                    product={post}
                    blog={true}
                    categories={categories_data}
                    list={categories}
                  />
                </Route>
              ))}

              <Route path="/materiale">
                <Materiale
                  materials={materials}
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>

              {materials_list.map((item) => (
                <Route key={item.id} path={"/material/" + item.id}>
                  <Material
                    db={db}
                    database={database}
                    storage={storage}
                    material={item}
                    categories={categories_data}
                    list={categories}
                  />
                </Route>
              ))}

              <Route path="/contact">
                <Contact
                  storage={storage}
                  database={database}
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
              <Route path="/rechizite">
                <Rechizite
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
              <Route path="/despre">
                <Despre
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
              <Route path="/blog">
                <Blog db={db} categories={categories_data} list={categories} posts={blogPosts}/>
              </Route>
              <Route path="/tehnica">
                <Tehnica
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
              <Route path="/parteneri">
                <Parteneri
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
              <Route path="/avantaje">
                <Avantaje
                  db={db}
                  categories={categories_data}
                  list={categories}
                />
              </Route>
            </Switch>
          </Router>,
          document.getElementById("root")
        );
      });
  });
