import React, { Component } from 'react'
import Footer from '../Footer'
import Header from '../Header'

export default class Despre extends Component {
    render() {
        return (
          <div>
            <Header db={this.props.db} categories={this.props.categories} />
            <div style={{margin:'20px auto', maxWidth:'1000px'}}>
              <h1 style={{textAlign:'center'}}>Despre agenția de servicii publicitare Total Gravura</h1>
              <p style={{margin:'30px', fontWeight:'light', fontSize:'1.1em'}}>
                
Total Gravura a fost înființată în 2005 ca o firmă de servicii de publicitate și
servicii de tăiere / gravare cu laser și frezar. Multă vreme, clientul principal au fost agențiile de publicitate, precum și companii mari precum British American Tobaco, Philip Morris, JTI, Samsung și altele.
Compania a fabricat produse pentru magazinele de electrocasnice, supermarketuri, chioșcuri. Accentul
a fost pus pentru publicitate exterioară, cu LED-uri, precum și pentru structuri de dimensiuni mari (standuri,
cutii, materiale POS). <br/>
Din 2015, am ineput o dezvoltarea activă a direcției de signalectică, precum și producției de cadouri și suvenire personalizate, aceasta a fost numita TG-decor. <br/>
Compania a făcut un mare salt înainte în 2016 – Atunci compania noastra a adus pe piața locală printer UV de la producătorul Japonez Mimaki, precum și printer eco-solvent cu plotter integrat, datorită căruia am reușit să începem dezvoltarea direcției de imprimare 
TG-Print și să aducem pe piață multe produse de consum interesante realizate prin tăiere în combinație cu imprimarea UV. În același an, compania începe să coopereze mai activ cu producătorii de mobilă, designerii, 
decoratori și alte sectoare, cu accent pe o mai mare dezvoltare B2B. Tot în 2016
au început primele livrări de produse Total Gravura către România, primii clienți au apărut în Italia
și Franța.  <br/>
În 2017 – Primele comenzi realizate pentru piața din Anglia, Irlanda, Germania, Belgia.
Din 2017, Total Gravura este un participant permanent la târgul de Crăciun de la Chișinău, unde pe parcursul unei luni întregi bucuram oamenii cu jucării, suvenire si cadouri de Crăciun.
Participarea la târguri a continuat în anii următori pe o scară mai mare - am reușit să vizităm
Orhei, Bălți și chiar Brașov(Romania). <br/>
În 2018 este un alt uriaș salt înainte, datorită noilor investiții făcute in echipamente puternice și  moderne. În anul acesta baza de producție a fost completată cu 4 noi
lasere, freze,  un laser cu fibră optică pentru tăierea metalelor, o mașină de elvețiană  ZUND pentru taiere digitală cu cuțit, 2 Imprimante pentru textile (sublimare și print direct), a doua imprimantă Mimaki UV, și o imprimantă UV de format mare pentru materiale în rolă, un calandru pentru tranfer de vopsele de pe hartie pe textil. Mulțumită acestui fapt, a fost posibilă lansarea multor produse noi și a apărut
oportunitatea de a oferi pieței un număr mare de servicii exclusive.  <br/>
2019 - arsenalul de producție al Total Gravura este completat cu imprimanta Konica Minolta, care ne ofera posibilitatea sa facem cărți de vizita buclete etc. Pe baza acestei imprimante, de asemenea
apare o nouă direcție a produselor EDUCARDS, care include producția de
jocuri educaționale, manuale pentru părinți, profesori, cărți educaționale etc.  <br/>
În ianuarie 2020, compania a devenit participantă la expoziția internațională Christmasworld 2020 din St.
Frankfurt pe Main, Germania, care a servit, de asemenea, ca un impuls pentru dezvoltarea unei rețele de clienți în țări din Europa, Ucraina, Rusia. Din păcate, situația globală cu Covid-19 nu a oferit o oportunitate
pentru a obține rezultatele complete din participarea la expoziție, dar suntem siguri că totul este încă în față. <br/>
Aprilie 2020 - Total Gravura a devenit una dintre puținele companii norocoase care au reușit
o reprofilare rapidă în timpul crizei pandemice. Am produs mai mult de 30 de mii
ecrane de protecție, păstrînd astfel locurile de muncă și posibilitatea dezvoltării continue a companiei.  <br/>
Ianuarie 2021 – arsenalul de producție a fost completat cu încă un printer UV Mimaki pentru produse suveneristice cu posibilitatea printarii pe suprafețe rotunde. 


              </p>
            </div>
              
            <Footer />
          </div>
        );
    }
}
